// REACT
import React, { useCallback } from "react";
import ReactTooltip from "react-tooltip";

// LIBRARIES
import { useT } from "@transifex/react";

// TYPES
import { YPositions, XPositions } from "p6m-viewData";
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";
import { CardActionButtonsProps as CardActionButtonsIndexProps } from "./index";
import { ACTIVATION_STATUS, LEARNING_DIRECTION, PHASE_OPTION } from "p6m-cards";
import { IdToOwnerId } from "p6m-subjects";

// COMPONENTS
import PopUp, { PopupActions } from "../../basic/popup/Popup";

// STYLED COMPONENTS
import {
    ActionText,
    ActionTooltip,
    Container,
    EntryLink,
    EntryText,
    List,
    StyledEntry,
    StyledPhaseSixIcon,
    StyledSimpleLink,
} from "./styles";

export interface CardActionButtonsProps extends CardActionButtonsIndexProps {
    selectedCardIds: IdToOwnerId[];
    isActive: boolean;
    isDeactivated: boolean;
    canBeModified: boolean;
    moveCards: () => void;
    editCard: () => void;
    deleteCards: () => void;
    changeActivationOfCards: (activationOptions: ACTIVATION_STATUS) => void;
    changePhaseOfCards: (phaseOption: PHASE_OPTION) => void;
    changeDirectionsOfCards: (directionOption: LEARNING_DIRECTION) => void;
}

const Entry = ({
    iconName,
    onClick,
    url,
    entryText,
    toolTipText,
}: {
    iconName: TIconNames;
    onClick?: () => void;
    url?: string;
    entryText: string;
    toolTipText: string;
}) => {
    return (
        <StyledEntry onClick={typeof url === "string" ? undefined : onClick}>
            <EntryLink
                href={typeof url === "string" ? url : undefined}
                target="_blank"
            >
                <EntryText>
                    <ActionText>{entryText}</ActionText>
                    <ActionTooltip>{toolTipText}</ActionTooltip>
                </EntryText>
                <StyledPhaseSixIcon name={iconName} />
            </EntryLink>
        </StyledEntry>
    );
};

const InteractiveIconWithToolTipOption = ({
    iconName,
    toolTipText,
    disabled,
    onClick,
}: {
    iconName: TIconNames;
    toolTipText: string;
    disabled?: boolean;
    onClick?: () => void;
}) => {
    const randomId = String(Math.random());
    return (
        <>
            <StyledSimpleLink
                data-for={randomId}
                data-tip={toolTipText}
                onClick={onClick}
            >
                <StyledPhaseSixIcon
                    name={iconName}
                    disabled={disabled}
                />
            </StyledSimpleLink>
            {!!toolTipText && <ReactTooltip id={randomId} />}
        </>
    );
};

const IconButtonWithPopUp = ({
    iconComponent,
    hide: hidePopUp,
    onPopUpClicked,
    position,
    popUpEntries,
}: {
    iconComponent: JSX.Element;
    hide?: boolean;
    onPopUpClicked?: (willBeOpen: boolean) => void;
    position: [YPositions, XPositions];
    popUpEntries: {
        onClick: () => void;
        iconName: TIconNames;
        entryText: string;
        toolTipText: string;
        hide?: boolean;
    }[];
}) => {
    const PopUpContent = (props: PopupActions) => (
        <List>
            {popUpEntries.map(({ iconName, onClick, entryText, toolTipText, hide: hideEntry }, i) => {
                const handleOnClick = useCallback(() => {
                    props.closePopup();
                    onClick();
                }, [onClick, props.closePopup]);

                return !hideEntry ? (
                    <Entry
                        key={`${iconName}_${i}`}
                        onClick={handleOnClick}
                        iconName={iconName}
                        entryText={entryText}
                        toolTipText={toolTipText}
                    />
                ) : null;
            })}
        </List>
    );

    return !hidePopUp ? (
        <PopUp
            onToggle={onPopUpClicked}
            arrow
            position={position}
            content={PopUpContent}
        >
            {iconComponent}
        </PopUp>
    ) : (
        iconComponent
    );
};

const CardActionButtons: React.FC<Omit<CardActionButtonsProps, "getCardDataById">> = (props) => {
    const {
        isSingleEntry,
        canSwap,
        popUpsPosition,
        onPopUpClicked,
        changePhaseOfCards,
        changeActivationOfCards,
        changeDirectionsOfCards,
        deleteCards,
        moveCards,
        editCard,
        isActive,
        isDeactivated,
        canBeModified,
    } = props;

    const t = useT();

    const relearnText = t("Learn from beginning", { _tags: "library,action" });
    const relearnTooltip = t("Set the learning progress of the cards back", {
        _tags: "library,action,tooltip",
    });
    const learnedText = t("Move to long-term memory", {
        _tags: "library,action",
    });
    const learnedTooltip = t("The cards will be moved to the final phase and will not be practised again", {
        _tags: "library,action,tooltip",
    });

    const progressTooltip = t("Set learning progress", {
        _tags: "library,cardActionButton,tooltip",
    });
    const activationTooltip = t("Change activation status", {
        _tags: "library,cardActionButton,tooltip",
    });
    const directionTooltip = t("Change learning direction", {
        _tags: "library,cardActionButton,tooltip",
    });
    const deleteTooltip = t("Delete card", {
        _tags: "library,cardActionButton,tooltip",
    });
    const deleteForbiddenTooltip = t("You cannot delete publisher cards", {
        _tags: "library,cardActionButton,tooltip",
    });
    const editTooltip = t("Edit card", {
        _tags: "library,cardActionButton,tooltip",
    });
    const moveToSubjectOrUnitTooltip = t("Move card(s) to different subject or unit", {
        _tags: "library,cardActionButton,tooltip",
    });

    const deactivateText = t("Deactivate", { _tags: "library,action" });
    const deactivateTooltip = t("Deactivated cards will not appear in your practice sessions again", {
        _tags: "library,action,tooltip",
    });
    const reactivateText = t("Reactivate", { _tags: "library,action" });
    const reactivateTooltip = t("Continue practicing previously deactivated cards", {
        _tags: "library,action,tooltip",
    });

    const forwardTestDirectionText = t("Practise this card in the original language direction", {
        _tags: "library,action",
    });
    const forwardTestDirectionTooltip = t(
        "This card will be practiced in the language direction that you defined when you created it.",
        { _tags: "library,action, tooltip" }
    );
    const backwardTestDirectionText = t("Practise this card in the opposite language direction", {
        _tags: "library,action",
    });
    const backwardTestDirectionTooltip = t("The language direction will be reversed", { _tags: "library,action" });
    const bothTestDirectionText = t("Practise this card in both language directions", { _tags: "library,action" });
    const bothTestDirectionTooltip = t(
        "You will have to practise the card in both directions. You will be asked to enter one of them, question or answer, respectively.",
        { _tags: "library,action" }
    );
    const nonSwappableTooltip = t("The language direction of this card cannot be changed", { _tags: "library,action" });
    const nonSwappableAllTooltip = t("The language direction of these cards cannot be changed", {
        _tags: "library,action",
    });

    const position: [YPositions, XPositions] = popUpsPosition
        ? popUpsPosition
        : isSingleEntry
        ? ["top", "right"]
        : ["bottom", "left"];

    const popUpEntriesPerActionButton: {
        [key: string]: {
            onClick: () => void;
            iconName: TIconNames;
            entryText: string;
            toolTipText: string;
            hide?: boolean;
        }[];
    } = {
        changeDirections: [
            {
                onClick: () => changeDirectionsOfCards("FORWARD"),
                iconName: "arrow-right",
                entryText: forwardTestDirectionText,
                toolTipText: forwardTestDirectionTooltip,
            },
            {
                onClick: () => changeDirectionsOfCards("BACKWARD"),
                iconName: "arrow-left",
                entryText: backwardTestDirectionText,
                toolTipText: backwardTestDirectionTooltip,
            },
            {
                onClick: () => changeDirectionsOfCards("BOTH"),
                iconName: "arrows-right-left",
                entryText: bothTestDirectionText,
                toolTipText: bothTestDirectionTooltip,
            },
        ],
        changePhaseStatus: [
            {
                onClick: () => changePhaseOfCards("RELEARN"),
                iconName: "arrow-left",
                entryText: relearnText,
                toolTipText: relearnTooltip,
            },
            {
                onClick: () => changePhaseOfCards("LEARNED"),
                iconName: "thumb-up",
                entryText: learnedText,
                toolTipText: learnedTooltip,
            },
        ],
        changeActivationStatus: [
            {
                onClick: () => changeActivationOfCards("DEACTIVATE"),
                iconName: "alarm-clock",
                entryText: deactivateText,
                toolTipText: deactivateTooltip,
                hide: !isActive,
            },
            {
                onClick: () => changeActivationOfCards("REACTIVATE"),
                iconName: "notification",
                entryText: reactivateText,
                toolTipText: reactivateTooltip,
                hide: !isDeactivated,
            },
        ],
    };

    const ChangeDirectionActionButtonForNonSwappableCard = () => {
        let text = nonSwappableAllTooltip;
        if (isSingleEntry) {
            text = nonSwappableTooltip;
        }

        return (
            <InteractiveIconWithToolTipOption
                iconName="arrows-right-left"
                toolTipText={text}
            />
        );
    };

    return (
        <Container className="cardActionButtons">
            <IconButtonWithPopUp
                hide={!isActive && !isDeactivated}
                onPopUpClicked={onPopUpClicked}
                position={position}
                iconComponent={
                    <InteractiveIconWithToolTipOption
                        iconName="p6"
                        toolTipText={progressTooltip}
                        disabled={!isActive && !isDeactivated}
                    />
                }
                popUpEntries={popUpEntriesPerActionButton.changePhaseStatus}
            />
            <IconButtonWithPopUp
                hide={!isActive && !isDeactivated}
                onPopUpClicked={onPopUpClicked}
                position={position}
                iconComponent={
                    <InteractiveIconWithToolTipOption
                        iconName="exercise-ok"
                        toolTipText={activationTooltip}
                        disabled={!isActive && !isDeactivated}
                    />
                }
                popUpEntries={popUpEntriesPerActionButton.changeActivationStatus}
            />
            {canSwap ? (
                <IconButtonWithPopUp
                    onPopUpClicked={onPopUpClicked}
                    position={position}
                    hide={!isActive}
                    iconComponent={
                        <InteractiveIconWithToolTipOption
                            iconName="arrows-right-left"
                            toolTipText={directionTooltip}
                            disabled={!isActive}
                        />
                    }
                    popUpEntries={popUpEntriesPerActionButton.changeDirections}
                />
            ) : (
                <ChangeDirectionActionButtonForNonSwappableCard />
            )}

            <InteractiveIconWithToolTipOption
                disabled={!canBeModified}
                onClick={moveCards}
                iconName="change-folder"
                toolTipText={moveToSubjectOrUnitTooltip}
            />
            {isSingleEntry && (
                <InteractiveIconWithToolTipOption
                    disabled={!canBeModified}
                    onClick={editCard}
                    iconName="edit-avatar"
                    toolTipText={editTooltip}
                />
            )}
            <InteractiveIconWithToolTipOption
                disabled={!canBeModified}
                onClick={deleteCards}
                iconName="exercise-failed"
                toolTipText={canBeModified ? deleteTooltip : deleteForbiddenTooltip}
            />
        </Container>
    );
};

export default CardActionButtons;
