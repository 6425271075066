// REACT
import React, { FunctionComponent, useState, useMemo, useCallback, ChangeEvent } from "react";
import { useT } from "@transifex/react";

// REDUX
import { useDispatch } from "react-redux";
import { actions as appStatusActions } from "../../../../redux/appStatus/appStatusSlice";
import { actions as responseActions } from "../../../../redux/response/responseSlice";

// COMPONENTS
import FilePreview, { Props as PreviewProps } from "../../../basic/filePreview/FilePreview";
import Component, { Props as ComponentProps, IdTypeDivider } from "./Files";

// NETWORKING
import { upload } from "../../../../networking/images";

const baseURL = process.env.REACT_APP_BACKEND_API_URL;
const fileLimit: number = parseInt(process.env.REACT_APP_MEDIA_MAX_SIZE_MB || "3");

export type Props = {
    files: ComponentProps["files"];
    type?: ComponentProps["type"];
    limit?: number;
    position?: ComponentProps["position"];
    onChange?: (files: ComponentProps["files"]) => void;
};

export const Files: FunctionComponent<Props> = (props) => {
    const { files = [], type = "all", limit, position, onChange = () => {} } = props;
    const t = useT();

    const [filePreview, setFilePreview] = useState<Partial<PreviewProps> | undefined>();

    const dispatch = useDispatch();

    const T_ErrorNoFiles = t("No file selected", { _tags: "response" });
    const T_ErrorUnsupportedFileType = t("File type is not supported", { _tags: "response" });
    const T_ServerError = t("Server Error, try later", { _tags: "response" });
    const T_UploadSuccessMessage = t("Media Upload Successful", { _tags: "response" });
    const T_MaxSizeLimit = t("Maximum file size to upload: {limit}.00 MB", { limit: fileLimit });

    const filesToShow = useMemo(() => {
        if (type === "all") return files;
        return files.filter((file) => {
            const fileType = file.split(IdTypeDivider)[1];
            return fileType === type;
        });
    }, [files, type]);

    const onDeleteCallback = useCallback(
        (file: Required<ComponentProps>["files"][0]) => {
            const deleteIndex = files.findIndex((findFile) => file === findFile);

            if (deleteIndex < 0) return;
            const filesToEdit = [...files];
            filesToEdit.splice(deleteIndex, 1);

            onChange(filesToEdit);
        },
        [files, onChange]
    );

    const onDownloadCallback = useCallback(
        async ({ target: { files: requestFiles } }: ChangeEvent<HTMLInputElement>) => {
            if (!requestFiles || !requestFiles.length) {
                dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ErrorNoFiles] }));
                return;
            }
            const file = requestFiles[0];
            const fileType = file.type.split("/")[0];
            const limitInBytes = fileLimit * 1000000;

            if (!["image", "video", "audio"].includes(fileType)) {
                dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ErrorUnsupportedFileType] }));
                return;
            }

            if (file.size > limitInBytes) {
                dispatch(responseActions.showResponse({ type: "ERROR", text: [T_MaxSizeLimit] }));
                return;
            }

            dispatch(appStatusActions.setLoading(true));
            try {
                const { data } = await upload([file]);

                if (data.httpCode !== 200) {
                    dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
                    return;
                }

                const {
                    replyContent: {
                        ids: [idWithOwner],
                    },
                } = data;

                if (!idWithOwner) {
                    dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
                    return;
                }

                dispatch(responseActions.showResponse({ type: "SUCCESS", text: [T_UploadSuccessMessage] }));

                const newFile: string = idWithOwner + IdTypeDivider + fileType;

                onChange([...files, newFile]);
            } catch (e) {
                console.log(e);
                dispatch(responseActions.showResponse({ type: "ERROR", text: [T_ServerError] }));
            } finally {
                dispatch(appStatusActions.setLoading(false));
            }
        },
        [
            dispatch,
            onChange,
            files,
            T_ErrorNoFiles,
            T_UploadSuccessMessage,
            T_ServerError,
            T_ErrorUnsupportedFileType,
            T_MaxSizeLimit,
        ]
    );

    const onPreviewCallback = useCallback((file: Required<ComponentProps>["files"][0]) => {
        const [id, type] = file.split(":");
        setFilePreview({
            url: `${baseURL}media/${id}`,
            type: type as "image",
        });
    }, []);

    return (
        <>
            <Component
                files={filesToShow}
                type={type}
                position={position}
                onDelete={onDeleteCallback}
                onDownload={onDownloadCallback}
                onPreview={onPreviewCallback}
                limit={limit}
            />
            {!!filePreview && (
                <FilePreview
                    {...filePreview}
                    onClose={() => setFilePreview(undefined)}
                />
            )}
        </>
    );
};
