import React, { forwardRef } from "react";
import DateBox from "../../components/basic/dateBox/DateBox";
import { CardEditor, Props as EditorProps } from "../../components/connected/addCards/cardEditor";
import { Row, Col, Container } from "../../components/basic/grid";
import { SubjectsMenu } from "../../components/connected/addCards/subjectsMenu";
import { UnitsMenu } from "../../components/connected/addCards/unitsMenu";
import { LanguageSelect } from "../../components/connected/addCards/languageSelect";
import { DictionaryButton } from "../../components/connected/addCards/dictionaryButton";
import { AddCardsButtons } from "../../components/connected/addCards/addCardsButtons";
import { ContentModal } from "../../components/connected/addCards/contentModal";
import { useT } from "@transifex/react";

import { ContentContainer, DictionaryContainer, FlagsContainer, FlagsCol, SelectorsRow } from "./styles";

type Props = {
    getQuestionRef: EditorProps["getRef"];
    getAnswerRef: EditorProps["getRef"];
    focusQuestion?: boolean;
    onDropdownToggle?: (state: boolean) => void;
};

const answerEditorPositions: EditorProps["positions"] = {
    audio: ["top", "right"],
};

const Add = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { getQuestionRef, getAnswerRef, focusQuestion, onDropdownToggle } = props;
    const t = useT();
    const t_questionPlaceholder = t("Gib hier die Frage ein", {});
    const t_answerPlaceholder = t("Gib hier die Antwort ein", {});

    return (
        <Container
            fullWidth
            ref={ref}
        >
            <Row>
                <Col xs={12}>
                    <Row horizontal="between">
                        <Col
                            xs={12}
                            m="none"
                        >
                            <SelectorsRow cols={{ xs: 1, m: 2 }}>
                                <Col>
                                    <SubjectsMenu onToggle={onDropdownToggle} />
                                </Col>
                                <Col>
                                    <UnitsMenu onToggle={onDropdownToggle} />
                                </Col>
                            </SelectorsRow>
                        </Col>
                        <Col
                            xs={12}
                            m="auto"
                        >
                            <Row>
                                <FlagsCol xs="auto">
                                    <FlagsContainer>
                                        <LanguageSelect type="primary" />
                                        <LanguageSelect type="secondary" />
                                    </FlagsContainer>
                                </FlagsCol>
                                <Col xs="auto">
                                    <DateBox />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ContentContainer>
                        <CardEditor
                            tabIndex={1}
                            type="question"
                            getRef={getQuestionRef}
                            placeholder={t_questionPlaceholder}
                            autoFocus={focusQuestion}
                        />
                        <DictionaryContainer>
                            <DictionaryButton />
                        </DictionaryContainer>
                        <CardEditor
                            tabIndex={2}
                            type="answer"
                            positions={answerEditorPositions}
                            getRef={getAnswerRef}
                            placeholder={t_answerPlaceholder}
                        />
                    </ContentContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <AddCardsButtons />
                </Col>
            </Row>
            <ContentModal />
        </Container>
    );
});

export default Add;
