import axios, { AxiosPromise } from "axios";
import { IResponse } from "p6m-response";
import { SubjectUnitCountResponseData } from "p6m-subjects";

export function getAllUnits(): AxiosPromise<IResponse<any>> {
    const data = {
        filterMode: "LIBRARY",
    };
    return axios.post("/unitsFiltered", data);
}

export function getSubjectUnits(groupId: string): AxiosPromise<IResponse<any>> {
    const data = {
        subjectId: groupId,
        filterMode: "LIBRARY",
    };
    return axios.post("/unitsFiltered", data);
}

export function getAllSubjectUnitsCount(): AxiosPromise<IResponse<{ units: SubjectUnitCountResponseData[] }>> {
    const data = {
        filterMode: "LIBRARY",
    };
    return axios.post("/unitsCountsFiltered", data);
}

export function getSubjectUnitsCount(
    groupId: string
): AxiosPromise<IResponse<{ units: SubjectUnitCountResponseData[] }>> {
    const data = {
        subjectId: groupId,
        filterMode: "LIBRARY",
    };
    return axios.post("/unitsCountsFiltered", data);
}

export function deleteUnit({ ownerId, unitId }: { ownerId: string; unitId: string }): any {
    return axios.delete(`/${ownerId}/units/${unitId}`);
}

export type PostUnitProps = {
    ownerId: string;
    subjectId: string;
    unitId: string;
    name: string;
    order?: number;
};
export function postUnits({ ownerId, unitId, subjectId, name, order }: PostUnitProps): any {
    const data = {
        name,
        ownerId,
        order,
        subjectIdToOwner: {
            id: subjectId,
            ownerId,
        },
    };
    return axios.post(`/${ownerId}/units/${unitId}`, data);
}
