// REACT
import React from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import {
    BackToMainView,
    CurrentTitle,
    HeaderWrapper,
    SettingsButtonContainer,
    StyledIconWrapper,
    StyledPhaseSixIcon,
} from "./styles";

export interface SettingsHeaderProps {
    currentSettingsName: string;
    closeAction?: () => any;
    backAction?: () => any;
    showBackButton: boolean;
}

const SettingsHeader: React.FC<SettingsHeaderProps> = (props) => {
    const t = useT();

    const t_settingsTitle = t("Settings", { _tags: "settings,header" });

    return (
        <HeaderWrapper>
            <SettingsButtonContainer>
                {props.showBackButton && props.backAction && (
                    <BackToMainView
                        onClickAction={props.backAction}
                        title={t_settingsTitle}
                        icon={"chevron-left"}
                        showArrow={false}
                        data-qa="user-settings-modal_back-button"
                    />
                )}
            </SettingsButtonContainer>
            <CurrentTitle data-qa="user-settings-modal_title">{props.currentSettingsName}</CurrentTitle>
            <StyledIconWrapper>
                <StyledPhaseSixIcon
                    name={"exercise-failed"}
                    onClick={props.closeAction}
                    data-qa="user-settings-modal_close-modal"
                />
            </StyledIconWrapper>
        </HeaderWrapper>
    );
};

export default SettingsHeader;
