import styled from "styled-components";
import SettingsMainStyledCTA from "../settingsMainStyledCTA/SettingsMainStyledCTA";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing}
        ${(props) => props.theme.base.spacing} 0;
    border-bottom: solid 1px ${(props) => props.theme.colors.backgroundMain};
`;

export const BackToMainView = styled(SettingsMainStyledCTA)`
    height: 40px;
    background: transparent;
    margin-bottom: 0;
`;

export const CurrentTitle = styled.span`
    flex: 1;
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleSize};
`;

export const SettingsButtonContainer = styled.div`
    flex: 1;
    min-width: 1px;
`;

export const StyledIconWrapper = styled.div`
    flex: 1;
    text-align: right;
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    cursor: pointer;
`;
