// REACT
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../redux/learning/learningSlice";
import { actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { selectors as goalsSelectors, actions as goalsActions } from "../../redux/goals/goalsSlice";
import { actions as warningsActions } from "../../redux/warnings/warningsSlice";

// COMPONENTS
import Component from "./Practice";

// UTILS
import { ampli, PracticeFinishedPxpProperties, PracticeStartedPxpProperties } from "../../ampli";
import { getTestResults } from "../../networking/tests";

const Practice: FunctionComponent = () => {
    const history = useHistory();
    const { testId } = useParams<{ testId?: string }>();

    const cards = useSelector(selectors.cards);
    const subjectId = useSelector(selectors.subjectId);
    const isFinished = useSelector(selectors.isFinished);
    const userGoals = useSelector(goalsSelectors.goals);
    const accelerate = useSelector(selectors.accelerate);
    const dispatch = useDispatch();

    const [cardsWereFetched, setCardsWereFetched] = useState<boolean>(false);

    useEffect(() => {
        dispatch(subjectActions.setActiveSubjectId(subjectId));
    }, [dispatch, subjectId]);

    // fetch goals if no data
    useEffect(
        function () {
            if (userGoals) return;
            dispatch(goalsActions.fetchGoals());
        },
        [userGoals, dispatch]
    );

    useEffect(() => {
        if (!isFinished || !accelerate) return;
        dispatch(actions.pushModal("done"));
    }, [dispatch, isFinished, accelerate]);

    useEffect(() => {
        if (!isFinished) return;
        if (testId) {
            dispatch(actions.savePracticeTestResult(testId));
        }
    }, [dispatch, isFinished, testId]);

    useEffect(
        function () {
            if (!isFinished) return;
            const {
                goals: { firstPracticeFinished = true },
            } = userGoals || { goals: { firstPracticeFinished: true } };
            if (firstPracticeFinished) return;
            dispatch(goalsActions.finishFirstPractice());
        },
        [dispatch, isFinished, userGoals]
    );

    useEffect(() => {
        if (cards.length) return;
        if (cardsWereFetched && !cards.length) {
            history.push("/home");
        } else {
            dispatch(actions.fetch());
            setCardsWereFetched(true);
        }
    }, [dispatch, cards]);

    useEffect(() => {
        dispatch(subjectActions.loadSubjectUnits(subjectId));
    }, [dispatch, subjectId]);

    useEffect(() => {
        dispatch(warningsActions.fetchWarnings());
    }, [dispatch]);

    useLogPracticeEvents(isFinished);

    return <>{!!cards && <Component />}</>;
};

export default Practice;

// todo: split these into multiple files in the future
function useLogPracticeEvents(isFinished: boolean) {
    const { testId } = useParams<{ testId?: string }>();
    const practiced = useSelector(selectors.filteredItems({ type: "practice" }));
    const practiceLogParams = useSelector(selectors.practiceLogParams);
    const isPracticeStartedDone = useRef<boolean>(false);
    const isPracticeFinishedDone = useRef<boolean>(false);

    const cards_learned: number = practiced.filter(({ resolved }) => resolved)?.length || 0;
    const getTestResultAmount = async (testId: string) => {
        const result = await getTestResults({ testId });
        return result.data.replyContent.objects.length;
    };

    useEffect(() => {
        if (!practiceLogParams || isPracticeFinishedDone.current || !isFinished) return;
        const { cards_for_practice, book_has_accessment_mode, activation_mode, mode } = practiceLogParams;

        const practiceFinishedProps: PracticeFinishedPxpProperties = {
            cards_for_practice,
            book_has_accessment_mode,
            activation_mode,
            mode,
            cards_learned,
        };

        if (practiceFinishedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                practiceFinishedProps.revision_no = resultAmount || 0;
            });

            if (
                practiceFinishedProps.mode === "exercise_moved_to_practice" ||
                practiceFinishedProps.mode === "exercise_only"
            ) {
                practiceFinishedProps.test_origin = "teacher";
                practiceFinishedProps.exercise_id = testId;
            }
        }

        isPracticeFinishedDone.current = true;
        ampli.practiceFinishedPxp(practiceFinishedProps);
    }, [practiceLogParams, cards_learned, isFinished, testId]);

    useEffect(() => {
        if (!practiceLogParams || isPracticeStartedDone.current) return;
        const { cards_for_practice, book_has_accessment_mode, activation_mode, mode, startsFrom } = practiceLogParams;
        if (!startsFrom) return;

        const practiceStartedProps: PracticeStartedPxpProperties = {
            cards_for_practice,
            book_has_accessment_mode,
            activation_mode,
            mode,
        };

        if (practiceStartedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                // as the first time the exercise will be done should result in the revisionNo equaling 1
                // we need to increase the number of already finished tests by 1 respectively
                practiceStartedProps.revision_no = (resultAmount || 0) + 1;
            });

            if (
                practiceStartedProps.mode === "exercise_moved_to_practice" ||
                practiceStartedProps.mode === "exercise_only"
            ) {
                practiceStartedProps.test_origin = "teacher";
                practiceStartedProps.exercise_id = testId;
            }
        }

        isPracticeStartedDone.current = true;
        ampli.practiceStartedPxp(practiceStartedProps);
    }, [practiceLogParams, testId]);
}
