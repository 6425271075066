import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import {
    selectors as goalsSelectors,
    actions as goalsActions,
    goalsToReachDerivedSelector,
} from "../../../redux/goals/goalsSlice";
import Component from "./TopNavigationCentralMenu";
import { IUserMetadata } from "p6m-user";

export const TopNavigationCentralMenu: FunctionComponent = () => {
    const userData: IUserMetadata = useSelector(userSelectors.userMetadata);
    const leftAchievementsCount = useSelector(goalsToReachDerivedSelector);
    const currentStreak = useSelector(goalsSelectors.currentStreak);

    const dispatch = useDispatch();

    const { hasFirstPractice = false } = userData;

    useEffect(() => {
        if (leftAchievementsCount !== undefined && currentStreak !== undefined) return;
        dispatch(goalsActions.fetchGoals());
    }, [leftAchievementsCount, currentStreak, dispatch]);

    return <Component hasFirstPractice={hasFirstPractice} />;
};
