// REACT
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal, { Props } from "../../../basic/modal/Modal";
import { Row, Col } from "../../../basic/grid";

// STYLED COMPONENTS
import { Content, Button, Footer } from "./styles";

const FirstCardModal: FunctionComponent<Partial<Props>> = (props) => {
    const { active, ...restProps } = props;

    return (
        <Modal
            {...restProps}
            active={active || false}
            title={<T _str="Don't forget to memorise new cards!" />}
            footerComponent={({ modalActions: { hide = () => {} } = {} }) => (
                <Footer>
                    <Row horizontal="end">
                        <Col xs="auto">
                            <Button onClick={hide}>
                                <T _str="OK, GOT IT!" />
                            </Button>
                        </Col>
                    </Row>
                </Footer>
            )}
            preventCloseOnBg
        >
            <Content>
                <T
                    _str="{title} {text} {tip}"
                    title={
                        <p>
                            <b>
                                <T _str="New cards are automatically saved to storage." />
                            </b>
                        </p>
                    }
                    text={
                        <p>
                            <T _str="Remember to memorise the cards to start the learning process." />
                        </p>
                    }
                    tip={
                        <p>
                            <i>
                                <T _str="Tip: Create new cards when you have the time. The activation step enables you to prepare cards in advance that you might not need right away." />
                            </i>
                        </p>
                    }
                />
            </Content>
        </Modal>
    );
};

export default memo(FirstCardModal);
