// LIBRARIES
import React, { useCallback, useState } from "react";

import { ampli } from "../../../ampli";

import ConfirmEditModal from "./ConfirmEditModal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { actions, selectors } from "../../../redux/add/addSlice";
import { GlobalModalView } from "../../../helpers/Modal";

const ConfirmEditModalWrapper: React.FC = (props) => {
    const subjectId = useSelector(selectors.getAddCardsSubjectId);
    const dontShowWarning = useSelector(selectors.getDontShowConfirmEditModal);
    const history = useHistory();
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(dontShowWarning || false);

    const close = useCallback(() => {
        dispatch(modalActions.setModalView(GlobalModalView.None));
    }, [dispatch]);

    const save = useCallback(() => {
        // save checkbox dontShowWarning
        if (toggle !== dontShowWarning) {
            dispatch(actions.toggleDontShowConfirmEditModal());
        }
        dispatch(actions.saveCard());
        close();

        // add a delay to allow time to save cards before redirecting
        setTimeout(() => history.push(`/manage/${subjectId}`), 1000);
        ampli.clickedEditCardsButton({
            cards_number: 1,
            user_action: "editCard",
        });
    }, [dispatch, history, dontShowWarning, toggle]);

    const setDontShowWarning = useCallback(() => {
        setToggle((prev) => !prev);
    }, []);

    return (
        <ConfirmEditModal
            close={close}
            save={save}
            dontShowWarning={toggle}
            setDontShowWarning={setDontShowWarning}
        />
    );
};

export default ConfirmEditModalWrapper;
