// Deps
import React from "react";
import { T } from "@transifex/react";

// Components
import Modal from "../../basic/modal/Modal";
import Description from "./subComponents/Description";
import Warning from "./subComponents/Warning";
import ConsumeButtonText from "./subComponents/ConsumeButtonText";

// Types
import { CoverImage } from "p6-react-shared";
import { SubjectAssignmentModal as ModalType } from "p6m-subjects";

// Styles
import {
    ButtonWrapper,
    CancelButton,
    ConsumeButton,
    ContentWrapper,
    InfoWrapper,
    SubjectTitle,
    Overlay,
} from "./styles";

interface SubjectAssignmentModalProps {
    data: ModalType;
    showOverlay: boolean;
    isStrictMode: boolean;
    onSubmit(): void;
    onCancel(): void;
}

export const SubjectAssignmentModal = ({
    data,
    showOverlay,
    isStrictMode,
    onSubmit,
    onCancel,
}: SubjectAssignmentModalProps) => {
    return (
        <>
            {showOverlay && <Overlay />}
            <Modal
                active
                headerComponent={false}
                afterClose={() => onCancel()}
            >
                <ContentWrapper>
                    <InfoWrapper isStrict={isStrictMode}>
                        <Description
                            totalCards={data.totalCards}
                            modalType={data.type}
                        />
                        <CoverImage
                            imageId={data.imageUrl}
                            responsive
                            size="small"
                            alt={`CoverImage${data.subjectName}`}
                        />
                        <SubjectTitle>{data.subjectName}</SubjectTitle>
                    </InfoWrapper>
                    <Warning
                        modalType={data.type}
                        isStrictMode={isStrictMode}
                    />
                    <ButtonWrapper>
                        <CancelButton
                            tabIndex={1}
                            onClick={onCancel}
                        >
                            <T
                                _str="Later"
                                _tags="Learn"
                            />
                        </CancelButton>

                        <ConsumeButton
                            tabIndex={1}
                            onClick={onSubmit}
                        >
                            <ConsumeButtonText
                                modalType={data.type}
                                isStrictMode={isStrictMode}
                            />
                        </ConsumeButton>
                    </ButtonWrapper>
                </ContentWrapper>
            </Modal>
        </>
    );
};
