//LIBRARIES
import React from "react";
import { useT } from "@transifex/react";

//STYLES
import {
    Background,
    ButtonsContainer,
    CloseButton,
    Content,
    Description,
    DontShowAgainInfo,
    ExtraInfo,
    FilledButton,
    SelectableSquareDiv,
    SupportLink,
    Title,
} from "./styles";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export interface SwitchInfoModalProps {
    closeFn: () => void;
    isPermanent: boolean;
    togglePermanent: () => void;
}

const SwitchInfoModal: React.FC<SwitchInfoModalProps> = (props) => {
    const { closeFn, isPermanent, togglePermanent } = props;
    const t = useT();

    const title_t = t("Looks different? Find out why!", { _tags: "modal, title" });

    const description_1 = t(
        "The new app interface allows features that will expand your app and improve your user experience.",
        { _tags: "modal, description" }
    );
    const description_2 = t(
        "But don't worry, you can continue learning seamlessly! The functionality is the same as in the old interface, which can no longer be used.",
        { _tags: "modal, description" }
    );
    const support_t = t("support", { _tags: "modal, description, link" });
    const supportLink = (
        <SupportLink
            href="https://www.phase-6.de/classic/service/support/contact/"
            target="_blank"
        >
            {support_t}
        </SupportLink>
    );
    const extraInfo_t = t(
        "Do you need help or have questions? We'll find a solution! Just contact our {supportLink}.",
        {
            _tags: "modal, description",
            supportLink,
        }
    );

    const okay_t = t("OK", { _tags: "modal, button, confirmation" });
    const dontShowThisAgain_t = t("Don't show this again", { _tags: "modal" });

    return (
        <Background>
            <CloseButton
                name="close"
                onClick={() => {
                    closeFn();
                }}
            />
            <Content>
                <Title>{title_t}</Title>
                <Description>
                    {description_1}
                    <br />
                    <br />
                    {description_2}
                </Description>
                <ExtraInfo>{extraInfo_t}</ExtraInfo>
            </Content>
            <ButtonsContainer>
                <FilledButton
                    onClick={() => {
                        closeFn();
                    }}
                >
                    {okay_t}
                </FilledButton>
                <DontShowAgainInfo>
                    {isPermanent ? (
                        <SelectableSquareDiv
                            onClick={togglePermanent}
                            highlighted
                        >
                            <PhaseSixIcon name="checkbox" />
                        </SelectableSquareDiv>
                    ) : (
                        <SelectableSquareDiv onClick={togglePermanent} />
                    )}
                    {dontShowThisAgain_t}
                </DontShowAgainInfo>
            </ButtonsContainer>
        </Background>
    );
};

export default SwitchInfoModal;
