//LIBRARIES
import axios, { AxiosPromise } from "axios";

//TYPES
import { IResponse } from "p6m-response";
import { UserSubjectsResponseData, IdToOwnerId, SubjectUnitCard } from "p6m-subjects";
import {
    CardContent,
    CardFilter,
    CardsResponseData,
    LEARNING_DIRECTION,
    PHASE_OPTION,
    SharingCardsListResponse,
    ShortLinkResponse,
} from "p6m-cards";

export { getSubjectUnitCards } from "./subjects";

export const getCardsFromSubject = ({
    searchString,
    subjectId,
    cardIds,
    phases,
    units = [],
    offset = 0,
    noLimit = false,
    defaultChunkSize = +(process.env.REACT_APP_CARD_CHUNK_SIZE || 100),
}: {
    searchString?: string;
    subjectId?: string;
    cardIds?: string[];
    phases?: number[];
    units?: string[];
    offset?: number;
    noLimit?: boolean;
    defaultChunkSize?: number;
}): AxiosPromise<IResponse<CardsResponseData>> => {
    const chunkSize = !noLimit ? defaultChunkSize : undefined;
    const data: CardFilter = {
        filterMode: "LIBRARY",
        subjectId,
        units: units,
        phases: phases,
        textContains: searchString,
        offset: offset,
        limit: chunkSize,
        cards: cardIds,
    };
    return axios.post("/cardList", data);
};

export function getUserCardList(): AxiosPromise<IResponse<UserSubjectsResponseData>> {
    return axios.get("/foreignCardListSend");
}

export function getGroupCardList(groupId: string): AxiosPromise<IResponse<UserSubjectsResponseData>> {
    return axios.get("/courseCardList/" + groupId);
}

export const changePhase = async (
    cardsIds: string[],
    phaseOption: PHASE_OPTION
): Promise<{
    success: boolean;
    phaseOption: PHASE_OPTION;
    cardsIds: string[];
}> => {
    const cardsFilter = {
        cards: cardsIds,
        changeState: phaseOption,
        filterMode: null,
        subjectId: null,
        units: [],
    };
    const response = await axios.post("changeProgressFiltered", cardsFilter);
    const changePhaseSuccess = response.data.httpCode === 200 && response.status === 200;

    return { success: changePhaseSuccess, phaseOption, cardsIds };
};

export const setCardsDirection = async (
    cardIds: string[],
    direction: LEARNING_DIRECTION
): Promise<{ success: boolean; cards: SubjectUnitCard[] }> => {
    const cardsFilterNormal = {
        cardActivationData: { activate: false, direction: "NORMAL" },
        cards: cardIds,
    };
    const cardsFilterOpposite = {
        cardActivationData: { activate: false, direction: "OPPOSITE" },
        cards: cardIds,
    };
    switch (direction) {
        case "FORWARD":
            cardsFilterNormal.cardActivationData.activate = true;
            cardsFilterOpposite.cardActivationData.activate = false;
            break;
        case "BACKWARD":
            cardsFilterNormal.cardActivationData.activate = false;
            cardsFilterOpposite.cardActivationData.activate = true;
            break;
        case "BOTH":
            cardsFilterNormal.cardActivationData.activate = true;
            cardsFilterOpposite.cardActivationData.activate = true;
            break;
    }

    try {
        const successResponse: { success: boolean; cards: SubjectUnitCard[] } = { success: false, cards: [] };
        for (const cardInfo of [cardsFilterNormal, cardsFilterOpposite]) {
            const response = await axios.post("cardActivationFiltered", cardInfo);
            const chanceDirectionSuccess = response.data.httpCode === 200 && response.status === 200;
            if (!chanceDirectionSuccess) {
                throw new Error();
            } else {
                successResponse.success = true;
                successResponse.cards = response.data.replyContent.cards as SubjectUnitCard[];
            }
        }
        return successResponse;
    } catch (e) {
        return { success: false, cards: [] };
    }
};

export async function reactivateCards(cardIdToOwnerList: IdToOwnerId[]): Promise<
    {
        cardId: string;
        success: boolean;
        activationStatus?: { NORMAL: boolean; OPPOSITE: boolean };
    }[]
> {
    const requests = cardIdToOwnerList.map(async (cardIdToOwner) => {
        try {
            const response = await axios.post("cards/reactivate", {
                idToOwner: cardIdToOwner,
            });
            const reactivationSuccess = response.data.httpCode === 200 && response.status === 200;

            return {
                success: reactivationSuccess,
                cardId: cardIdToOwner.id,
                activationStatus: reactivationSuccess
                    ? (response.data.replyContent?.activationStatus as {
                          NORMAL: boolean;
                          OPPOSITE: boolean;
                      })
                    : undefined,
            };
        } catch (e) {
            return {
                success: false,
                cardId: cardIdToOwner.id,
                activationStatus: undefined,
            };
        }
    });
    return await Promise.all(requests);
}

export const deactivateCards = async (cardsIds: string[]): Promise<{ success: boolean; cards: SubjectUnitCard[] }> => {
    const activationDataTemplate: CardFilter = {
        units: [],
        subjectId: null,
        filterMode: null,
        cards: cardsIds,
    };

    const activationDataToSend: CardFilter[] = [
        {
            ...activationDataTemplate,
            cardActivationData: { activate: false, direction: "NORMAL" },
        },
        {
            ...activationDataTemplate,
            cardActivationData: { activate: false, direction: "OPPOSITE" },
        },
    ];

    try {
        const successResponse: { success: boolean; cards: SubjectUnitCard[] } = { success: false, cards: [] };
        for (const deactivateItem of activationDataToSend) {
            const response = await axios.post("cardActivationFiltered", deactivateItem);
            const deactivateSuccess = response.data.httpCode === 200 && response.status === 200;
            if (!deactivateSuccess) {
                throw new Error();
            } else {
                successResponse.success = true;
                successResponse.cards = response.data.replyContent.cards as SubjectUnitCard[];
            }
        }
        return successResponse;
    } catch (e) {
        return { success: false, cards: [] };
    }
};

export const deleteCards = async (
    cardIdToOwnerList: IdToOwnerId[]
): Promise<{ cardId: string; success: boolean }[]> => {
    const requests = cardIdToOwnerList.map(async (cardIdToOwner) => {
        const url = `${cardIdToOwner.ownerId}/cards/${cardIdToOwner.id}`;
        try {
            const response = await axios.delete(url);
            const deletionSuccess = response.data.httpCode === 200 && response.status === 200;

            if (!deletionSuccess) {
                throw new Error();
            }
            return { cardId: cardIdToOwner.id, success: true };
        } catch (e) {
            return { cardId: cardIdToOwner.id, success: false };
        }
    });

    return await Promise.all(requests);
};

export function getCardByIdAndUserId(cardId: string, ownerId: string): AxiosPromise<IResponse<CardContent>> {
    return axios.get(ownerId + "/cards/" + cardId);
}

export const postNewCard = async (cardId: string, ownerId: string, card: CardContent) => {
    return axios.post(ownerId + "/cards/" + cardId, card);
};

type Annotations = {
    questionAnnotation?: string;
    answerAnnotation?: string;
};

export function updateCardAnnotation(
    cardId: string,
    subjectOwnerId: string,
    ownerId: string,
    subjectId: string,
    data: Annotations
): AxiosPromise {
    const result = {
        subjectIdToOwner: {
            ownerId: subjectOwnerId,
            id: subjectId,
        },
        idToOwner: {
            ownerId: ownerId,
            id: cardId,
        },
        ...data,
    };
    return axios.post(`${ownerId}/subject/${subjectId}/${ownerId}/cards/${cardId}/annotation`, result);
}

export function getCardAnnotation(cardId: string, ownerId: string, subjectId: string) {
    return axios.get(`${ownerId}/subject/${subjectId}/${ownerId}/cards/${cardId}/annotation`);
}

export type GetCardContentProps = {
    cardId: string;
    ownerId: string;
};
export function getCardContent({ ownerId, cardId }: GetCardContentProps): AxiosPromise<IResponse<CardContent>> {
    return axios.get(`${ownerId}/cards/${cardId}`);
}

export function sharingCardsList(): AxiosPromise<IResponse<SharingCardsListResponse>> {
    return axios.get(`sharingList`);
}

export function getShortLink(data: { addSessionId: string; link: string }): AxiosPromise<IResponse<ShortLinkResponse>> {
    return axios.post("FBShortLink", data);
}
