// LIBRARIES
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../../../redux/add/addSlice";

// COMPONENTS
import Component from "../../../complex/addCards/LanguageSelect/LanguageSelect";

type Props = {
    type: "primary" | "secondary";
};

export const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { type } = props;
    const langSelectors = {
        primary: selectors.getAddCardsPrimaryLang,
        secondary: selectors.getAddCardsSecondaryLang,
    };

    const langSelector = langSelectors[type];
    const lang = useSelector(langSelector);
    const dispatch = useDispatch();

    const onChangeCallback = useCallback(
        (newLang: string) => {
            const action = {
                primary: actions.setCardPrimaryLang,
                secondary: actions.setCardSecondaryLang,
            }[type];
            dispatch(action(newLang));
        },
        [dispatch, type]
    );

    return (
        <Component
            language={lang}
            onChange={onChangeCallback}
        />
    );
};
