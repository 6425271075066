// LIBRARIES
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useT } from "@transifex/react";
import { cloneDeep, debounce } from "lodash";

// NETWORKING
import { getCardsFromSubject } from "../../networking/cards";

// UTILS
import { ResponseType } from "../../constants/ResponseConstants";
import { getUnitOptionsFromSubject } from "../../helpers/Subjects";

//STYLED-COMPONENTS
import { BottomContainer, Container, ContentContainer, DropdownContainer, TitleContainer } from "./styles";

// COMPONENTS
import LibrarySubjectEntry from "../../components/connected/librarySubjectEntry/LibrarySubjectEntry";
import DropdownSelection from "../../components/basic/dropdownSelection/DropdownSelection";
import SearchTextInput from "../../components/basic/searchTextInput/SearchTextInput";
import Loading from "../../components/connected/loading";

// TYPES
import { User } from "p6m-user";
import { SubjectData, SubjectUnitCard, EntryChange } from "p6m-subjects";
import { DropdownOption } from "p6m-viewData";
export interface ManageProps {
    user: User;
    isTeacher: boolean;
    allUserSubjects: SubjectData[];
    subject: SubjectData;
    subjectId: string;
    availablePhases: number[];
    subjectOptions: DropdownOption[];
    initialSearchText: string;
    onSubjectSelect: (subjectId: string | undefined, searchValue: string) => void;
    deleteSubject: (subjectId: string) => void;
    setResponse: (type: ResponseType, text: string) => void;
    changeSubject: (index: number, name: string) => void;
    resetSubjectEdit: () => void;
    saveSubjectEdit: (changes: EntryChange[]) => void;
    saveUnitEdit: (unitId: string, unitName: string, toDelete?: boolean) => void;
    deleteUnits: (changes: EntryChange[]) => void;
}

type CardData = {
    list: SubjectUnitCard[];
    hasMore: boolean;
};

const CHUNK_SIZE: number = +(process.env.REACT_APP_CARD_CHUNK_SIZE || 50);

const Manage: React.FC<ManageProps> = (props) => {
    const t = useT();
    const {
        user,
        isTeacher,
        subject: currentSubject,
        subjectOptions: currentSubjectOptions,
        subjectId: currentSubjectId,
        setResponse,
        availablePhases,
        initialSearchText = "",
        onSubjectSelect: selectSubject,
        changeSubject,
        deleteSubject,
        resetSubjectEdit,
        saveSubjectEdit,
        saveUnitEdit,
        deleteUnits,
    } = props;

    const activationPhase_t = t("Activation phase", { _tags: "title" });
    const longTermMemory_t = t("Long-term memory", { _tags: "title" });
    const phaseText = t("Phase", { _tags: "title" });
    const loadCardErrorText = t("Cards could not be loaded.", {
        _tags: "error,tooltip",
    });
    const subjectLabel = t("Subject", { _tags: "library,label" });
    const unitLabel = t("Unit", { _tags: "library,label" });
    const phaseLabel = t("Phase", { _tags: "library,label" });

    const [finishedInitialLoad, setFinishedInitialLoad] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const [cardData, setCardData] = useState<CardData>({
        list: [],
        hasMore: true,
    });

    // filter options
    const [unitOptions, setUnitOptions] = useState<DropdownOption[]>([]);

    const [phaseOptions, setPhaseOptions] = useState<DropdownOption[]>([]);

    // search by text
    const [searchValue, setSearchValue] = useState(initialSearchText);

    const [selectedUnitIds, setSelectedUnitIds] = useState<string[]>([]);
    const [selectedPhaseIds, setSelectedPhaseIds] = useState<string[]>([]);
    const [dataCallOffset, setDataCallOffset] = useState<number>(0);
    const [dataCallClear, setDataCallClear] = useState<boolean>(false);

    const forbiddenSubjectNames = props.allUserSubjects
        .filter((subject) => {
            return props.subjectOptions.every((option) => option.value !== subject.subjectMetadata.subjectIdToOwner.id);
        })
        .map((subject) => subject.subjectContent.name);

    const loadCards = useCallback(
        async ({
            subjectId,
            unitIds,
            phaseIds,
            searchString,
        }: {
            subjectId?: string;
            unitIds?: string[];
            phaseIds?: string[];
            searchString?: string;
        }) => {
            const searchUnits = unitOptions
                .filter((unit) => unit.value && (unitIds || selectedUnitIds).includes(unit.value))
                .map((unit) => unit.value!);

            const phases = phaseOptions
                .filter((phase) => phase.value && (phaseIds || selectedPhaseIds).includes(phase.value))
                .map((phase) => Number(phase.value!));

            const response = await getCardsFromSubject({
                subjectId: subjectId || currentSubjectId,
                units: searchUnits,
                phases: phases,
                searchString: typeof searchString === "string" ? searchString : searchValue.trim(),
                offset: scrolled ? dataCallOffset : undefined,
                defaultChunkSize: !cardData.list.length && dataCallOffset ? dataCallOffset + CHUNK_SIZE : undefined,
            });

            if (response.status === 200 && response.data.httpCode === 200) {
                const loadedCards = response.data.replyContent.cards;
                // if no card or not the limit chunk size amount is returned we are finished
                setCardData((cardData) => ({
                    list: scrolled && !dataCallClear ? cardData.list.concat(loadedCards) : loadedCards,
                    hasMore: !!loadedCards.length && loadedCards.length % CHUNK_SIZE === 0,
                }));
            } else {
                setResponse("ERROR", loadCardErrorText);
            }
            setFinishedInitialLoad(true);
            setScrolled(false);
        },
        [
            unitOptions,
            searchValue,
            scrolled,
            cardData.list.length,
            setResponse,
            loadCardErrorText,
            phaseOptions,
            dataCallClear,
            dataCallOffset,
            currentSubjectId,
            selectedUnitIds,
            selectedPhaseIds,
        ]
    );

    const loadCardsRef = useRef(loadCards);
    loadCardsRef.current = loadCards;

    const fetchCardsDebounced = useMemo(() => {
        return debounce(
            (props: { subjectId?: string; unitIds?: string[]; phaseIds?: string[]; searchString?: string }) =>
                loadCardsRef.current(props),
            500
        );
    }, [loadCardsRef]);

    const onPhaseChange = (phases: string[]) => {
        setSelectedPhaseIds(phases);
        loadCards({
            phaseIds: phases,
        });
    };

    const canSwap: boolean = useMemo(() => {
        return !cardData.list.find((card) => {
            const {
                cardContent: { swappable },
            } = card;
            return !swappable;
        });
    }, [cardData.list]);

    const handleDeleteSubject = useCallback(
        (subjectId: string) => {
            deleteSubject(subjectId);
        },
        [deleteSubject]
    );

    //SUBJECT DROPDOWN HANDLER
    const handleDropDownSelectSubject = (newSubjectIds: string[]) => {
        const subjectIdToSwitchTo = newSubjectIds[0];
        if (subjectIdToSwitchTo && currentSubjectId !== subjectIdToSwitchTo) {
            selectSubject(subjectIdToSwitchTo, searchValue);
            setCardData(() => ({
                list: [],
                hasMore: true,
            }));
        }
    };

    //UNIT DROPDOWN HANDLER
    const handleDropDownSelectUnit = (units: string[]) => {
        setSelectedUnitIds(units);
        loadCards({
            unitIds: units,
        });
    };

    const handleDropDownChangeUnit = (index: number, unitName: string) => {
        const newUnitOptions = cloneDeep(unitOptions);
        newUnitOptions[index].title = unitName;
        setUnitOptions(newUnitOptions);
    };

    const handleDropDownResetUnitEdit = () => {
        setUnitOptions(getUnitOptionsFromSubject(currentSubject, "" + user.userDnsId, isTeacher));
    };

    const handleDropDownSaveUnitEdit = useCallback(
        (changes: EntryChange[]) => {
            const unitsToDelete: EntryChange[] = [];
            changes.forEach((change) => {
                if (change.deleted) {
                    unitsToDelete.push(change);
                } else if (change.id !== "") {
                    saveUnitEdit(change.id, change.name, change.deleted);
                }
            });
            if (unitsToDelete.length > 0) {
                deleteUnits(unitsToDelete);
            }
        },
        [saveUnitEdit, deleteUnits]
    );

    //SEARCH AND SCROLL
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (input.trimStart() !== searchValue.trimStart()) {
            setSearchValue(input);
            if (input.trim() === "") {
                setCardData({ hasMore: true, list: [] });
            }

            fetchCardsDebounced({ searchString: input.trim() });
        }
    };

    const handleSearchClear = () => {
        setSearchValue("");
        setCardData({ hasMore: true, list: [] });

        loadCards({ searchString: "" });
    };

    const onScroll = useCallback(() => {
        if (finishedInitialLoad && cardData.hasMore && cardData.list.length <= dataCallOffset + CHUNK_SIZE) {
            setDataCallClear(false);
            setDataCallOffset(dataCallOffset + CHUNK_SIZE);
            setScrolled(true);

            loadCardsRef.current({});
        }
    }, [cardData.hasMore, cardData.list.length, dataCallOffset, finishedInitialLoad]);

    useEffect(() => {
        const phaseLimitStrings = {
            0: activationPhase_t,
            [availablePhases.length - 1]: longTermMemory_t,
        };
        const effectPhaseOptions: DropdownOption[] = availablePhases.map((phase) => ({
            title: phaseLimitStrings[phase] || `${phaseText} ${phase}`,
            value: phase.toString(),
            editable: false,
        }));

        setPhaseOptions(effectPhaseOptions);
    }, [phaseText, availablePhases, activationPhase_t, longTermMemory_t]);

    useEffect(() => {
        const newOptions = getUnitOptionsFromSubject(currentSubject, "" + user.userDnsId, isTeacher);
        setUnitOptions(newOptions);
        setSelectedPhaseIds([]);
        setSelectedUnitIds([]);
    }, [currentSubject, user.userDnsId, isTeacher]);

    useEffect(() => {
        setDataCallOffset(0);
    }, [selectedUnitIds, selectedPhaseIds, currentSubjectId]);

    useEffect(() => {
        setDataCallClear(true);
    }, [selectedUnitIds, selectedPhaseIds, currentSubjectId]);

    useEffect(() => {
        loadCards({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSubjectId]);

    return (
        <Container>
            <TitleContainer>
                <DropdownContainer>
                    <DropdownSelection
                        label={subjectLabel}
                        options={currentSubjectOptions}
                        multiSelection={false}
                        selectedIds={[currentSubjectId]}
                        saveIds={handleDropDownSelectSubject}
                        editable
                        forbiddenNames={forbiddenSubjectNames}
                        onChange={changeSubject}
                        resetEdit={resetSubjectEdit}
                        saveEdit={saveSubjectEdit}
                        contentType="subjects"
                    />
                    <DropdownSelection
                        label={unitLabel}
                        options={unitOptions}
                        multiSelection
                        selectedIds={selectedUnitIds}
                        saveIds={handleDropDownSelectUnit}
                        editable
                        onChange={handleDropDownChangeUnit}
                        resetEdit={handleDropDownResetUnitEdit}
                        saveEdit={handleDropDownSaveUnitEdit}
                        contentType="units"
                    />
                    <DropdownSelection
                        label={phaseLabel}
                        options={phaseOptions}
                        multiSelection
                        selectedIds={selectedPhaseIds}
                        saveIds={onPhaseChange}
                        editable={false}
                        contentType="phases"
                    />
                </DropdownContainer>
                <SearchTextInput
                    onChange={handleSearchChange}
                    onClear={handleSearchClear}
                    value={searchValue}
                    fixedWidth
                />
            </TitleContainer>
            <ContentContainer>
                <Loading />
                {cardData && (
                    <LibrarySubjectEntry
                        finishedInitialLoad={finishedInitialLoad}
                        isOverview={false}
                        canSwap={canSwap}
                        subject={currentSubject}
                        cards={cardData.list}
                        units={unitOptions}
                        isFirstInList
                        limitCardsShown={false}
                        onScroll={onScroll}
                        hasMoreCards={cardData.hasMore}
                        refreshCardList={() => {
                            fetchCardsDebounced({});
                        }}
                    />
                )}
            </ContentContainer>
            <BottomContainer />
        </Container>
    );
};

export default Manage;
