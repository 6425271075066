// REACT
import React, { FunctionComponent, memo, useState, useEffect, useRef, useContext } from "react";

// LIBRARIES
import { useT } from "@transifex/react";
import { CoverImage } from "p6-react-shared";

// COMPONENTS
import { Row, Col } from "../../../../basic/grid";
import LanguageSelect from "../../LanguageSelect/LanguageSelect";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { getSubjectCover } from "../../../../../helpers/Subjects";

import { InputError } from "../ModalCreation/styles";
import { LanguagesContext } from "../../../../../context/languages";

// TYPES
import { SubjectData } from "p6m-subjects";

// STYLED COMPONENTS
import {
    Wrapper,
    CoverImageCol,
    FlagRow,
    FlagCol,
    Title,
    Input,
    EditIcon,
    LanguageImage,
    ActionsWrapper,
    ActionClose,
    ActionSave,
    TitleWrapper,
    ItemRow,
    ItemInformationCol,
} from "./styles";

type Result = {
    value: string;
    primaryLang: string;
    secondaryLang: string;
};

export type Props = {
    subject: SubjectData;
    userDnsId?: string;
    edit?: boolean;
    setEdit?: (state: boolean) => void;
    onEdit: (subject: SubjectData, newData: Result) => Promise<void>;
    onSelect: (subject: SubjectData) => void;
};

const SubjectItem: FunctionComponent<Props> = (props) => {
    const { getFlagSource } = useContext(LanguagesContext);

    const { subject, userDnsId = "", edit = false, setEdit = () => {}, onEdit, onSelect } = props;
    const {
        subjectContent: { primaryLang, secondaryLang, name },
        subjectMetadata: {
            subjectIdToOwner: { ownerId },
        },
    } = subject;
    const [newPrimaryLang, setPrimaryLang] = useState<string>(primaryLang);
    const [newSecondaryLang, setSecondaryLang] = useState<string>(secondaryLang);
    const [error, setError] = useState<string>("");

    const value = useRef(name);
    const t = useT();

    useEffect(() => {
        value.current = name;
        if (!edit) return;
        setPrimaryLang(primaryLang);
        setSecondaryLang(secondaryLang);
        setError("");
    }, [edit, primaryLang, secondaryLang, name]);

    const t_placeholder = t("write the subject name here", {});

    const cover = getSubjectCover(subject);

    const isMySubject: boolean = userDnsId === ownerId;

    const onChange = ({ target: { value: newValue } }: any) => {
        value.current = newValue;
        setError("");
    };

    return (
        <Wrapper
            $edit={edit}
            onClick={() => {
                if (edit) return;
                onSelect(subject);
            }}
        >
            <ItemRow vertical="center">
                <CoverImageCol xs="auto">
                    <CoverImage
                        imageId={cover}
                        alt={"Subject Cover"}
                        size={"small"}
                    />
                </CoverImageCol>
                <ItemInformationCol>
                    <Row>
                        <Col xs={12}>
                            {edit ? (
                                <>
                                    <Input
                                        defaultValue={value.current}
                                        onChange={onChange}
                                        placeholder={t_placeholder}
                                        minLength={
                                            process.env.REACT_APP_INPUT_CHARS_MIN
                                                ? +process.env.REACT_APP_INPUT_CHARS_MIN
                                                : 1
                                        }
                                        maxLength={
                                            process.env.REACT_APP_INPUT_CHARS_MAX
                                                ? +process.env.REACT_APP_INPUT_CHARS_MAX
                                                : 254
                                        }
                                    />
                                    {!!error && <InputError>{error}</InputError>}
                                </>
                            ) : (
                                <TitleWrapper>
                                    <Title>{name}</Title>
                                    {isMySubject && (
                                        <EditIcon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEdit(true);
                                            }}
                                        >
                                            <PhaseSixIcon name="edit-avatar" />
                                        </EditIcon>
                                    )}
                                </TitleWrapper>
                            )}
                        </Col>
                    </Row>
                    <FlagRow>
                        <Col xs="auto">
                            {edit ? (
                                <Row>
                                    <Col xs="auto">
                                        <LanguageSelect
                                            withArrow
                                            position={["top", "center"]}
                                            language={newPrimaryLang}
                                            onChange={setPrimaryLang}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <LanguageSelect
                                            withArrow
                                            position={["top", "center"]}
                                            language={newSecondaryLang}
                                            onChange={setSecondaryLang}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <FlagCol xs="auto">
                                        <LanguageImage
                                            src={getFlagSource(primaryLang)}
                                            alt={primaryLang}
                                        />
                                    </FlagCol>
                                    <FlagCol xs="auto">
                                        <LanguageImage
                                            src={getFlagSource(secondaryLang)}
                                            alt={secondaryLang}
                                        />
                                    </FlagCol>
                                </Row>
                            )}
                        </Col>
                    </FlagRow>
                </ItemInformationCol>
                {edit && (
                    <Col xs="auto">
                        <ActionsWrapper onClick={(e: any) => e.stopPropagation()}>
                            <ActionClose
                                onClick={() => {
                                    setEdit(false);
                                }}
                            >
                                <PhaseSixIcon name="close" />
                            </ActionClose>
                            <ActionSave
                                $disabled={!value}
                                onClick={() => {
                                    onEdit(subject, {
                                        value: value.current,
                                        primaryLang: newPrimaryLang,
                                        secondaryLang: newSecondaryLang,
                                    })
                                        .then(() => {
                                            setEdit(false);
                                        })
                                        .catch((e: any) => {
                                            if (e) setError(e);
                                        });
                                }}
                            >
                                <PhaseSixIcon name="exercise-ok" />
                            </ActionSave>
                        </ActionsWrapper>
                    </Col>
                )}
            </ItemRow>
        </Wrapper>
    );
};

export default memo(SubjectItem);
