// LIBRARIES
import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { cloneDeep, debounce } from "lodash";
import { useT } from "@transifex/react";

//REDUX
import { useDispatch } from "react-redux";
import { actions as loadingActions } from "../../redux/appStatus/appStatusSlice";

//NETWORKING
import { getCardsFromSubject } from "../../networking/cards";

//COMPONENTS
import LibrarySubjectEntry from "../../components/connected/librarySubjectEntry/LibrarySubjectEntry";
import SearchTextInput from "../../components/basic/searchTextInput/SearchTextInput";

//TYPES
import { SubjectData, SubjectUnitCard } from "p6m-subjects";

export interface ManageProps {
    subjects: SubjectData[];
    onSubjectSelect: (subjectId: string | undefined, searchValue: string) => void;
    deleteSubject: (subjectId: string) => void;
    availablePhases: number[];
}

const Container = styled.div`
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
const TitleContainer = styled.div`
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
const ContentContainer = styled.div`
    padding-right: 10px;
    overflow: auto;
    overscroll-behavior-x: none;
`;
const Title = styled.h2`
    margin: 0;
    color: ${(props) => props.theme.colors.textOnBackground};
    font-weight: 500;
    font-size: ${(props) => props.theme.base.cardPracticeTextSize};
`;
const EmptyResultContainer = styled.div`
    display: flex;
    margin-top: ${(props) => props.theme.base.spacingTiny};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadius};
    height: 92px; // equal to the image height plus margin and padding to keep the height of the container the same
    align-items: center;
`;
const EmptyResultText = styled.span`
    padding-left: ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.text};
`;

const Manage: React.FC<ManageProps> = (props) => {
    const t = useT();
    const dispatch = useDispatch();

    const { subjects, deleteSubject, onSubjectSelect } = props;

    const defaultCardsPerSubjectOfUser = subjects.reduce(
        (userSubjectsMap, subject) => ({
            ...userSubjectsMap,
            [subject.subjectMetadata.subjectIdToOwner.id]: [],
        }),
        {} as { [key: string]: SubjectUnitCard[] }
    );

    const [cardsToShowPerSubject, setCardsToShowPerSubject] = useState<{
        [key: string]: SubjectUnitCard[];
    }>({});
    const [noCardsMatched, setNoCardsMatched] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");
    const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
    const [cardLoadInProgress, setCardLoadInProgress] = useState(true);
    const [cardCount, setCardCount] = useState<number>(0);

    const libraryTitle = t("Library", { _tags: "library,label" });
    const noSearchResultsText = t("No results were found.", {
        _tags: "noResultsText",
    });

    const trimmedSearchValue = searchValue.trim();
    const units = useMemo(() => [], []);

    const loadCards = useCallback(
        async (input: string) => {
            const loadedCardsPerSubject: { [key: string]: SubjectUnitCard[] } = cloneDeep(defaultCardsPerSubjectOfUser);
            if (input.trim().length) {
                dispatch(loadingActions.setLoading(true));
                const response = await getCardsFromSubject({
                    searchString: input.trim(),
                    noLimit: true,
                });

                const cardsMatchingSearchString = response?.data.replyContent.cards || [];
                if (!cardsMatchingSearchString.length) {
                    setNoCardsMatched(true);
                } else {
                    for (const card of cardsMatchingSearchString) {
                        const cardSubjectId = card.cardContent.subjectIdToOwner.id;
                        loadedCardsPerSubject[cardSubjectId]?.push(card);
                    }
                }
                setCardLoadInProgress(false);
                dispatch(loadingActions.setLoading(false));
            }
            setCardsToShowPerSubject(loadedCardsPerSubject);
        },
        [defaultCardsPerSubjectOfUser, dispatch]
    );

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setSearchValue(input);
        if (input.trim() !== trimmedSearchValue) {
            if (input.trim() === "") {
                setCardsToShowPerSubject(defaultCardsPerSubjectOfUser);
            } else {
                setCardLoadInProgress(true);
                setNoCardsMatched(false);
            }
        }
    };

    const debounceRefreshState = useCallback(
        (initialValue: number = 0) =>
            debounce(() => setRefreshSearch(true), initialValue, {
                leading: false,
                trailing: true,
            })(),
        []
    );

    const handleSearchClear = () => {
        if (searchValue.trim()) {
            setRefreshSearch(true);
        }
        setSearchValue("");
        setNoCardsMatched(false);
        setCardsToShowPerSubject(defaultCardsPerSubjectOfUser);
    };

    const goToSubject = useCallback(
        (subjectId: string) => {
            onSubjectSelect(subjectId, searchValue);
        },
        [onSubjectSelect, searchValue]
    );

    const handleScroll = useCallback(() => {}, []);

    //EFFECT
    useEffect(() => {
        debounceRefreshState(500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        setCardCount(
            subjects.reduce((acc, subject) => {
                if (
                    subject.groupedCardCount &&
                    subject.groupedCardCount.cardCounts &&
                    subject.groupedCardCount.cardCounts.LIBRARY
                ) {
                    return acc + subject.groupedCardCount?.cardCounts.LIBRARY.cardCount;
                }
                return acc;
            }, 0)
        );
        setRefreshSearch(true);
    }, [subjects]);

    useEffect(() => {
        if (refreshSearch) {
            loadCards(searchValue);
            setRefreshSearch(false);
        }
    }, [loadCards, refreshSearch, searchValue]);

    return (
        <Container>
            <TitleContainer>
                <Title>
                    {libraryTitle} ({cardCount.toLocaleString("de-DE")})
                </Title>
                <SearchTextInput
                    onChange={handleSearchTextChange}
                    onClear={handleSearchClear}
                    value={searchValue}
                    fixedWidth
                />
            </TitleContainer>
            <ContentContainer>
                {noCardsMatched ? (
                    <EmptyResultContainer>
                        <EmptyResultText>{noSearchResultsText}</EmptyResultText>
                    </EmptyResultContainer>
                ) : (
                    subjects.map((subject, index) => {
                        const subjectId = subject.subjectMetadata.subjectIdToOwner.id;
                        if (!trimmedSearchValue.length || cardsToShowPerSubject[subjectId]?.length) {
                            // show if no search or has cards
                            return (
                                <LibrarySubjectEntry
                                    key={`subject_${index}`}
                                    finishedInitialLoad={!cardLoadInProgress}
                                    isOverview={true}
                                    canSwap={false}
                                    subject={subject}
                                    cards={cardsToShowPerSubject[subjectId]}
                                    units={units}
                                    isSearchResult={!!cardsToShowPerSubject[subjectId]?.length}
                                    isFirstInList={index === 0}
                                    goToSubject={goToSubject}
                                    deleteSubject={deleteSubject}
                                    limitCardsShown={true}
                                    onScroll={handleScroll}
                                    hasMoreCards={false}
                                    refreshCardList={debounceRefreshState}
                                />
                            );
                        }
                        return null;
                    })
                )}
            </ContentContainer>
        </Container>
    );
};

export default Manage;
