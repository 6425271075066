import styled from "styled-components";

export const FlagImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

export const FlagImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    cursor: pointer;
`;

export const Arrow = styled.div`
    color: ${({ theme: { colors } }) => colors.text};
    cursor: pointer;
`;

export const FlagsContainer = styled.ul`
    list-style: none;
    max-height: 350px;
    padding: 0;
    margin: 0 -${({ theme: { base } }) => base.spacingSmall};
    overflow-y: auto;
`;

export const FlagItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${({ theme: { base } }) => base.spacingInContent} ${({ theme: { base } }) => base.spacingInContent};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors } }) => colors.text};
    line-height: 1;
    cursor: pointer;
    transition: background-color ${({ theme: { base } }) => base.transition};

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    }

    & > img {
        height: 24px;
        width: 36px;
        margin-left: ${({ theme: { base } }) => base.spacingSmall};
        border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    }

    & > span {
        font-size: ${({ theme: { base } }) => base.mediumSize};
    }
`;
