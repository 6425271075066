import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const OkPhaseSixIcon = styled(PhaseSixIcon)`
    margin-left: 45px;
`;
export const LanguageComboList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlagImage = styled.img`
    height: 24px;
    width: 36px;
    border-radius: ${(props) => props.theme.base.borderRadius};
    margin: ${(props) => props.theme.base.spacingSmall};
`;

export const LanguageComboEntry = styled.button<{ active: boolean }>`
    display: flex;
    border-radius: ${(props) => props.theme.base.borderRadius};
    background: ${(props) =>
        props.active ? props.theme.colors.backgroundContrast : `${props.theme.colors.backgroundContrast}cc`};
    margin-bottom: ${(props) => props.theme.base.spacingInContent};
    align-items: center;
    cursor: pointer;
    outline: inherit;
    border: none;
    padding: 0;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundContrast};
    }

    &:hover span {
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const LanguageSelectorText = styled.span<{ active: boolean }>`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};

    &:first-of-type {
        margin-left: 0;
    }
`;
