// LIBRARIES
import React, { useContext } from "react";

// COMPONENTS
import PhaseSixIcon, { TIconNames } from "../phaseSixIcon/PhaseSixIcon";

// STYLES COMPONENTS
import { ThemeContext } from "styled-components";
import { StyledIcon, InputContainer, StyledInput } from "./styles";

export interface TextInputProps {
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    color?: string;
    type?: string;
    iconName?: TIconNames;
    inputProps?: any;
}

const TextInput: React.FC<TextInputProps> = (props) => {
    const { value, iconName, placeholder, color, type, onChange, inputProps, ...restProps } = props;
    const theme = useContext(ThemeContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    let icon = null;
    if (iconName) {
        icon = (
            <StyledIcon>
                <PhaseSixIcon name={iconName} />
            </StyledIcon>
        );
    }

    return (
        <InputContainer {...restProps}>
            {icon}
            <StyledInput
                {...inputProps}
                onChange={handleChange}
                value={value}
                placeholder={placeholder ? placeholder : ""}
                color={color ? color : theme.colors.text}
                type={type ? type : "text"}
            />
        </InputContainer>
    );
};

export default TextInput;
