//LIBRARIES
import React, { FunctionComponent, memo } from "react";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import { ModalHeader } from "../../basic/modal/styles";
import { CloseIcon, Background, StyledIframe } from "./styles";

interface GDPRModalProps extends GlobalModalProps {
    url: string;
}

const GDPRModal: FunctionComponent<GDPRModalProps> = (props) => {
    const { url, close } = props;

    return (
        <Background>
            <ModalHeader>
                <CloseIcon
                    onClick={close}
                    name={"close"}
                />
            </ModalHeader>
            <StyledIframe src={url} />
        </Background>
    );
};

export default memo(GDPRModal);
