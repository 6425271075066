//LIBRARIES
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

//STYLES
import * as Styled from "../../basic/modal/styles";

interface SelectIncludedContentModalProps extends GlobalModalProps {
    url: string;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;

const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

const DefaultHeader: FunctionComponent<{ title: string; close: () => any }> = ({ title, close }) => (
    <Styled.ModalHeader>
        <Styled.Title>{title.toUpperCase() || ""}</Styled.Title>
        <Styled.CloseIcon
            onClick={close}
            name={"close"}
        />
    </Styled.ModalHeader>
);

const SelectIncludedContentModal: React.FC<SelectIncludedContentModalProps> = (props) => {
    const t = useT();

    const share_t = t("Choose a content", { _tags: "title, modal" });

    return (
        <Background>
            <DefaultHeader
                title={share_t}
                close={props.close}
            />
            <StyledIframe src={props.url} />
        </Background>
    );
};

export default SelectIncludedContentModal;
