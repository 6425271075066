// Dependencies
import React from "react";

// Components
import { NoTests } from "./NoTests";
import { TestListItem } from "../TestListItem/TestListItem";
import { RenameTestModal } from "../modals/RenameTestModal";
import { DeleteTestModal } from "../modals/DeleteTestModal";

//TYPE
import { TestListItemInfo, TestsType } from "p6m-tests";

// Styles
import { TestsListContainer } from "../styles";

// HOOKS
import { useTestActions } from "../../../hooks/useTestActions";

interface TestsListProps {
    tests: TestListItemInfo[];
    testsType: TestsType;
}

export const TestsList = ({ tests, testsType }: TestsListProps) => {
    const {
        delete: { showDeleteModal, closeDeleteModal, handlePrepareTestDeletion, deleteTest },
        rename: { oldTestName, showRenameModal, closeRenameModal, handlePrepareTestRenaming, renameTest },
    } = useTestActions();

    if (tests.length === 0) {
        return <NoTests testsType={testsType} />;
    }

    return (
        <>
            <TestsListContainer>
                {tests.map((test) => (
                    <TestListItem
                        key={test.id}
                        test={test}
                        clickable
                        onDelete={handlePrepareTestDeletion}
                        onRename={handlePrepareTestRenaming}
                    />
                ))}
            </TestsListContainer>

            <RenameTestModal
                oldName={oldTestName}
                active={showRenameModal}
                onClose={closeRenameModal}
                onSubmit={renameTest}
            />

            <DeleteTestModal
                active={showDeleteModal}
                onClose={closeDeleteModal}
                onSubmit={deleteTest}
            />
        </>
    );
};
