// REACT
import React, { FunctionComponent } from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import { Header, Image, Message, Overlay } from "./styles";

const MaintenancePage: FunctionComponent = () => {
    const t = useT();
    const headerText_t = t("Sit tight", { _tags: "maintenance,title" });
    const messageText_t = t("we're just replacing one or two bulbs", { _tags: "maintenance,message" });

    return (
        <Overlay>
            <Header>{headerText_t}</Header>
            <Image src={require("../../../assets/img/p6online_logo_rgb_maintenance_final.svg")} />
            <Message>{messageText_t}</Message>
        </Overlay>
    );
};

export default MaintenancePage;
