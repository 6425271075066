import React, { FunctionComponent, memo } from "react";

import { ModalHeader } from "../../basic/modal/styles";
import { Background, StyledIframe } from "../GDPRModal/styles";
import { useT } from "@transifex/react";
import { Button, ButtonsContainer } from "../changeCardSubjectModal/styles";

export interface AgreementModalProps {
    url: string;
    accept: () => void;
    decline: () => void;
}

const AgreementModal: FunctionComponent<AgreementModalProps> = (props) => {
    const t = useT();
    const { url, accept, decline } = props;

    const title_t = t("In order to obtain access you need to accept agreement", { _tags: "modal,title" });
    const accept_t = t("accept", { _tags: "modal,agb,button" });
    const decline_t = t("decline", { _tags: "modal,agb,button" });

    return (
        <Background>
            <ModalHeader>{title_t}</ModalHeader>
            <StyledIframe src={url} />
            <ButtonsContainer>
                <Button onClick={decline}>{decline_t}</Button>
                <Button
                    onClick={accept}
                    highlighted
                >
                    {accept_t}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default memo(AgreementModal);
