// REACT
import React, { FunctionComponent, useContext, useState, useCallback } from "react";

// COMPONENTS
import Popup from "../../../basic/popup/Popup";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { LanguageCodes, LanguagesContext } from "../../../../context/languages";

// STYLED COMPONENTS
import { FlagImage, FlagsContainer, FlagItem, FlagImageWrapper, Arrow } from "./styles";

type Props = {
    language?: string;
    withArrow?: boolean;
    position?: any;
    onChange?: (langKey: string) => void;
};

const LanguageSelect: FunctionComponent<Props> = (props) => {
    const { languageTranslations, getFlagSource } = useContext(LanguagesContext);
    const [popupOpen, setPopupOpen] = useState(false);

    const onToggle = (isOpen: boolean) => {
        setPopupOpen(isOpen);
    };

    const { language = "others", onChange, withArrow = false, position = ["bottom", "center"] } = props;

    const contentAsCallback = useCallback(
        ({ closePopup }) => {
            return (
                <FlagsContainer>
                    {Object.entries(languageTranslations).map(([languageCode, languageNameTranslation]) => {
                        return (
                            <FlagItem
                                key={languageCode}
                                onClick={() => {
                                    if (!!onChange) onChange(languageCode);
                                    closePopup();
                                }}
                            >
                                <span>{languageNameTranslation}</span>
                                <img
                                    src={getFlagSource(languageCode as LanguageCodes)}
                                    alt=""
                                />
                            </FlagItem>
                        );
                    })}
                </FlagsContainer>
            );
        },
        [languageTranslations, onChange]
    );

    return (
        <Popup
            autoPosition
            position={position}
            content={contentAsCallback}
            minChildWidth
            arrow
            onToggle={onToggle}
        >
            <FlagImageWrapper>
                <FlagImage src={getFlagSource(language as LanguageCodes)} />
                {withArrow && (
                    <Arrow>
                        <PhaseSixIcon name={popupOpen ? "chevron-up" : "chevron-down"} />
                    </Arrow>
                )}
            </FlagImageWrapper>
        </Popup>
    );
};

export default LanguageSelect;
