import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    actions as goalsActions,
    goalsToShowDerivedSelector,
    selectors as goalsSelectors,
} from "../../redux/goals/goalsSlice";
import Component from "./Achievements";
import { GoalsContext } from "../../context/goals";

const AchievementsWrapper: React.FC = () => {
    const userGoals = useSelector(goalsSelectors.goals);
    const goalsToShow = useSelector(goalsToShowDerivedSelector);
    const goalsContext = useContext(GoalsContext);
    const firstPracticeFinished = userGoals?.goals.firstPracticeFinished;

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (firstPracticeFinished) return;
        if (!userGoals) {
            dispatch(goalsActions.fetchGoals());
            return;
        }
        history.push("/home");
    }, [dispatch, firstPracticeFinished, history, userGoals]);

    // do not show if user goals aren't loaded yet
    if (userGoals) {
        return (
            <Component
                userGoals={userGoals}
                goalsToShow={goalsToShow}
                goals={goalsContext}
            />
        );
    }
    return null;
};

export default AchievementsWrapper;
