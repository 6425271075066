// LIBRARIES
import { AnyAction, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// REDUX
import { reducer as userReducer } from "../redux/user/userSlice";
import { reducer as appStatusReducer } from "../redux/appStatus/appStatusSlice";
import { reducer as responseReducer } from "../redux/response/responseSlice";
import { reducer as themeReducer, ThemeState } from "../redux/theme/themeSlice";
import { reducer as goalsReducer } from "../redux/goals/goalsSlice";
import { reducer as subjectsReducer } from "../redux/subjects/subjectsSlice";
import { reducer as practiceReducer } from "../redux/practice/practiceSlice";
import { reducer as activationReducer } from "../redux/activation/activationSlice";
import { reducer as addReducer } from "../redux/add/addSlice";
import { reducer as manageReducer } from "../redux/manage/manageSlice";
import { reducer as prepareReducer } from "../redux/prepare/prepareSlice";
import { reducer as approveReducer } from "../redux/approve/approveSlice";
import { reducer as gamesReducer } from "../redux/games/gamesSlice";
import { reducer as settingsReducer } from "../redux/settings/settingsSlice";
import { reducer as modalReducer } from "../redux/modal/modalSlice";
import { reducer as notificationsReducer } from "../redux/notifications/notificationsSlice";
import { reducer as avatarsReducer } from "../redux/avatars/avatarsSlice";
import { reducer as warningsReducer } from "../redux/warnings/warningsSlice";
import { reducer as learningReducer } from "../redux/learning/learningSlice";
import { reducer as loginReducer } from "../redux/login/loginSlice";
import { reducer as testsReducer } from "../redux/tests/testsSlice";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../redux/rootSaga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const devMode = process.env.NODE_ENV !== "production";

// add logger for dev mode
if (devMode) {
    middleware.push(logger);
}

const userPersistConfig = { key: "user", storage };
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistSubjectConfig = { key: "practice", storage };
const persistedSubjectsReducer = persistReducer(persistSubjectConfig, subjectsReducer);
const persistPracticeConfig = { key: "practice", storage };
const persistedPracticeReducer = persistReducer(persistPracticeConfig, practiceReducer);
const themePersistConfig = { key: "theme", storage, stateReconciler: autoMergeLevel2, blacklist: ["theme"] }; // set theme from name via saga on rehydrate action
const persistedThemeReducer = persistReducer<ThemeState, AnyAction>(themePersistConfig, themeReducer);
// const subjectsPersistConfig = {key: 'subjects', storage};
// const persistedSubjectsReducer = persistReducer(subjectsPersistConfig, subjectsReducer);
const persistLearningConfig = {
    key: "learning",
    storage,
    whitelist: [
        "subjectId",
        "cardsId",
        "type",
        "direction",
        "session",
        "filter",
        "isPrepareForTest",
        "usersToNotShowExitIntentPopup",
    ],
};
const persistLearningReducer = persistReducer(persistLearningConfig, learningReducer);

const persistedAddConfig = {
    key: "add",
    storage,
    whitelist: ["dontShowConfirmEditModal", "firstCardReminderShownTo", "snapshot"],
};
const persistedAddReducer = persistReducer(persistedAddConfig, addReducer);

const persistedTestsConfig = {
    key: "tests",
    storage,
};
const persistedTestsReducer = persistReducer(persistedTestsConfig, testsReducer);

export const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        appStatus: appStatusReducer,
        response: responseReducer,
        theme: persistedThemeReducer,
        goals: goalsReducer,
        subjects: persistedSubjectsReducer,
        practice: persistedPracticeReducer,
        activation: activationReducer,
        add: persistedAddReducer,
        manage: manageReducer,
        prepare: prepareReducer,
        approve: approveReducer,
        games: gamesReducer,
        settings: settingsReducer,
        modal: modalReducer,
        notifications: notificationsReducer,
        avatars: avatarsReducer,
        warnings: warningsReducer,
        learning: persistLearningReducer,
        login: loginReducer,
        tests: persistedTestsReducer,
    },
    middleware: [sagaMiddleware],
    devTools: devMode,
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
