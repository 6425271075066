// REACT
import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors as userSelectors } from "../../../../../redux/user/userSlice";
import { selectors as goalsSelectors, actions as goalsActions } from "../../../../../redux/goals/goalsSlice";
import { selectors } from "../../../../../redux/learning/learningSlice";

// TYPES
import { Props as ModalProps } from "../../../../basic/modal/Modal";

// COMPONENTS
import Component, { Props as ComponentProps } from "./Phase";

export const Phase: FunctionComponent<Partial<ModalProps>> = (props) => {
    const preferencesPhases = useSelector(userSelectors.getUserPhases);
    const userGoals = useSelector(goalsSelectors.goals);
    const phase = useSelector(selectors.cardPhase);

    const buttonRef = useRef<HTMLButtonElement>();

    const dispatch = useDispatch();

    useEffect(() => {
        if (userGoals) return;
        dispatch(goalsActions.fetchGoals());
    }, [userGoals, dispatch]);

    useEffect(
        function () {
            const tabListener = (e: KeyboardEvent) => {
                if (e.code !== "Tab") return;
                e.preventDefault();
            };

            if (!buttonRef.current) return;

            document.addEventListener("keydown", tabListener);
            buttonRef.current.focus();
            return function () {
                document.removeEventListener("keydown", tabListener);
            };
        },
        [buttonRef.current]
    );

    const userPhases: ComponentProps["userPhases"] = useMemo(() => {
        return preferencesPhases.slice(1).map(({ durationDays }, i) => ({
            name: i + 1,
            duration: `+${durationDays}`,
        }));
    }, [preferencesPhases]);

    return (
        <Component
            phase={phase}
            userPhases={userPhases}
            hasFirstPractice={userGoals?.goals.firstPracticeFinished || false}
            buttonRef={buttonRef}
            {...props}
        />
    );
};
