import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const PopupWrapper = styled.div`
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 250px;
    left: 25%;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
`;

export const PopupContentContainerLight = styled.div`
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
`;

export const Section = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacing};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const SchoolSearchList = styled.div`
    max-height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const SchoolListEntry = styled.div<{ isMainSchool?: boolean }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: ${(props) => props.theme.base.spacingSmall};
    margin-bottom: 1px;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => props.theme.colors.backgroundContrast};
    cursor: pointer;

    color: ${(props) => (props.isMainSchool ? props.theme.colors.primary : props.theme.colors.text)};

    // show child 2 placeholder when not hovered or is main
    > div:nth-child(2) {
        display: ${(props) => (props.isMainSchool ? "none" : "inherit")};
    }
    > div:nth-child(3) {
        display: ${(props) => (props.isMainSchool ? "inherit" : "none")};
    }
    // show child 3 action buttons when mouse over
    &:hover {
        > div:nth-child(2) {
            display: none;
        }
        > div:nth-child(3) {
            display: inherit;
        }
    }
`;

export const SchoolDataWrapper = styled.div`
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const Name = styled.span`
    font-weight: bold;
`;

export const Address = styled.span``;

export const ActionButtonsPlaceholder = styled.div`
    flex: 1;
`;
export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export const HeaderSection = styled.div`
    padding: 15px;
    width: 100%;
`;

export const Headline = styled.span`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleSize};
`;

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Text = styled.div`
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.text};
`;

export const Value = styled.div`
    font-size: ${(props) => props.theme.base.bigSize};
    color: ${(props) => props.theme.colors.primary};
`;

export const Information = styled.p`
    color: ${(props) => props.theme.colors.text};
`;

export const Footer = styled.p`
    padding: ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;

export const CloseIcon = styled.div`
    font-size: ${(props) => props.theme.base.smallSize};

    &:hover {
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`;

export const ContentWrapper = styled.div`
    padding: 0 15px;
`;

export const HoverColorIcon = styled(PhaseSixIcon)`
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;
