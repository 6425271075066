// REACT
import React, { useCallback, useState, ReactNode, FC, useMemo, memo } from "react";
import { InView } from "react-intersection-observer";

// LIBRARIES
import DOMPurify from "dompurify";

// TYPES
import { TIconNames } from "../phaseSixIcon/PhaseSixIcon";
import { CardInfoForActionButtons } from "p6m-cards";

// COMPONENTS
import CardActionButtons from "../../complex/cardActionButtons";
import ListCheckbox from "../listCheckbox/ListCheckbox";
import { getCardData } from "../../../helpers/Cards";
import Modal from "../modal/Modal";

// THEME
import { StyleTheme } from "p6m-themes";

// STYLED COMPONENTS
import {
    Container,
    IconContainer,
    LastColumn,
    PhaseDirection,
    PhaseText,
    Text,
    WordWrapper,
    ModalImageContainer,
    ModalImage,
    StyledPhaseSixIcon,
    ArrowPhaseSixIcon,
    ListCheckBoxWrapper,
} from "./styles";

export interface CardEntryProps {
    theme?: StyleTheme;
    userId?: string;
    cardId: string;
    cardOwnerId: string;
    normalIsActive: boolean;
    oppositeIsActive: boolean;
    normalWasActive: boolean;
    oppositeWasActive: boolean;
    normalPhase: number;
    oppositePhase: number;
    contentQuestion: string;
    contentAnswer: string;
    cardIsSwappable: boolean;
    isSearchResult?: boolean;
    isSelected: boolean;
    toggleCard: (cardId: string) => void;
    unitName: string;
    subjectId: string;
    refreshCardList?: (initialValue?: number) => void | undefined;
}

const UnitWrapper: FC<{ children?: ReactNode; className?: string }> = ({ children, className }) => (
    <div className={className}>
        <Text>{children}</Text>
    </div>
);

const Icon = ({
    hide,
    name,
    active,
    selected,
    onClick,
}: {
    hide?: boolean;
    name: TIconNames;
    active: boolean;
    selected: boolean;
    onClick: () => void;
}) =>
    !hide ? (
        <IconContainer>
            <StyledPhaseSixIcon
                name={name}
                active={active}
                highlighted={selected}
                onClick={onClick}
            />
        </IconContainer>
    ) : null;

const CardTextsPlusInteractionIcons = memo(
    ({
        className,
        text,
        isSelected,
        isActive,
        audios,
        images,
        onAudioIconClick,
        onImageIconClick,
    }: {
        className?: string;
        text: string;
        isSelected: boolean;
        isActive: boolean;
        audios: any[];
        images: any[];
        onAudioIconClick: () => void;
        onImageIconClick: () => void;
    }) => (
        <WordWrapper className={className}>
            <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text, { USE_PROFILES: { html: true } }) }} />
            <Icon
                hide={!audios.length}
                name="speaker"
                selected={isSelected}
                active={isActive}
                onClick={onAudioIconClick}
            />
            <Icon
                hide={!images.length}
                name="pic"
                selected={isSelected}
                active={isActive}
                onClick={onImageIconClick}
            />
        </WordWrapper>
    )
);

const CardEntry: React.FC<CardEntryProps> = (props) => {
    const {
        cardId,
        cardOwnerId,
        normalIsActive,
        normalWasActive,
        oppositeIsActive,
        oppositeWasActive,
        normalPhase,
        oppositePhase,
        contentAnswer,
        contentQuestion,
        cardIsSwappable,
        isSearchResult,
        isSelected: cardIsSelected,
        toggleCard,
        unitName,
        subjectId,
        refreshCardList,
    } = props;

    const isCardActive = oppositeIsActive || normalIsActive;
    const isCardDeactivated = (oppositeWasActive || normalWasActive) && !isCardActive;

    const [modal, setModal] = useState<"question" | "answer" | null>(null);
    const [buttonsActive, setButtonsActive] = useState<number>(0);

    const cardQuestionData = useMemo(() => getCardData(contentQuestion), [contentQuestion]);
    const questionText = cardQuestionData.title;
    const cardAnswerData = useMemo(() => getCardData(contentAnswer), [contentAnswer]);
    const answerText = cardAnswerData.title;

    const cardDataById: { [key: string]: CardInfoForActionButtons } = useMemo(() => {
        return {
            [cardId]: {
                idToOwnerId: { id: cardId, ownerId: cardOwnerId },
                normal: {
                    active: normalIsActive,
                    wasActive: normalWasActive,
                },
                opposite: {
                    active: oppositeIsActive,
                    wasActive: oppositeWasActive,
                },
            },
        };
    }, [cardId, cardOwnerId, normalIsActive, oppositeIsActive, normalWasActive, oppositeWasActive]);

    const playQuestionSound = useCallback(() => {
        cardQuestionData.playAudios[0](true);
    }, [cardQuestionData]);
    const playAnswerSound = useCallback(() => cardAnswerData.playAudios[0](true), [cardAnswerData]);
    const showQuestionExample = useCallback(() => setModal("question"), []);
    const showAnswerExample = useCallback(() => setModal("answer"), []);

    const onPopUpClicked = useCallback((willBeOpen: boolean) => {
        setButtonsActive((buttonsActive) => (willBeOpen ? ++buttonsActive : --buttonsActive));
    }, []);

    //Card-Edit-Handlers
    const handleToggleSelectCard = useCallback(() => {
        toggleCard(cardId);
    }, [cardId, toggleCard]);

    return (
        <InView>
            {({ inView, ref }) => (
                <Container
                    ref={ref}
                    className={`LCE_${
                        isCardActive ? "Active" : isCardDeactivated ? "ManuallyDeactivated" : "DuplicateOrPhase0"
                    }`}
                    key={`card_${cardId}`}
                    actionButtonsActivated={!!buttonsActive}
                    active={isCardActive}
                    selected={cardIsSelected}
                    isSearchResult={isSearchResult}
                >
                    {!isSearchResult && (
                        <ListCheckBoxWrapper className="checkboxColumn">
                            <ListCheckbox
                                checked={cardIsSelected}
                                onChange={handleToggleSelectCard}
                            />
                        </ListCheckBoxWrapper>
                    )}
                    <CardTextsPlusInteractionIcons
                        className="questionTextColumn"
                        text={questionText}
                        isSelected={cardIsSelected}
                        isActive={isCardActive}
                        audios={cardQuestionData.playAudios}
                        images={cardQuestionData.images}
                        onAudioIconClick={playQuestionSound}
                        onImageIconClick={showQuestionExample}
                    />
                    <CardTextsPlusInteractionIcons
                        className="answereTextColumn"
                        text={answerText}
                        isSelected={cardIsSelected}
                        isActive={isCardActive}
                        audios={cardAnswerData.playAudios}
                        images={cardAnswerData.images}
                        onAudioIconClick={playAnswerSound}
                        onImageIconClick={showAnswerExample}
                    />
                    <UnitWrapper className="unitLabelColumn">{unitName}</UnitWrapper>
                    <LastColumn className="actionAndDirectionColumn">
                        <div className="phase-directions">
                            <PhaseDirection active={normalIsActive}>
                                <PhaseText>{normalPhase}&nbsp;</PhaseText>
                                <ArrowPhaseSixIcon
                                    name="arrow-right"
                                    highlighted={cardIsSelected && normalIsActive}
                                />
                            </PhaseDirection>
                            <PhaseDirection active={oppositeIsActive}>
                                <PhaseText>{oppositePhase}&nbsp;</PhaseText>
                                <ArrowPhaseSixIcon
                                    name="arrow-left"
                                    highlighted={cardIsSelected && oppositeIsActive}
                                />
                            </PhaseDirection>
                        </div>
                        {inView && (
                            <CardActionButtons
                                isSingleEntry
                                canSwap={cardIsSwappable}
                                cardDataById={cardDataById}
                                onPopUpClicked={onPopUpClicked}
                                subjectId={subjectId}
                                refreshCardList={refreshCardList}
                            />
                        )}
                    </LastColumn>
                    {!!modal && (
                        <Modal
                            active={!!modal}
                            title={modal === "answer" ? answerText : questionText}
                            afterClose={() => setModal(null)}
                        >
                            <ModalImageContainer>
                                {modal === "answer"
                                    ? cardAnswerData.images &&
                                      cardAnswerData.images.map((image) => <ModalImage src={image} />)
                                    : cardQuestionData.images &&
                                      cardQuestionData.images.map((image) => <ModalImage src={image} />)}
                            </ModalImageContainer>
                        </Modal>
                    )}
                </Container>
            )}
        </InView>
    );
};

export default React.memo(CardEntry);
