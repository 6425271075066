import styled from "styled-components";

export const Content = styled.div<{ $edit: boolean }>`
    height: 80vh;
    overflow-y: auto;
    padding: 0 ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    opacity: ${({ $edit }) => ($edit ? 0.5 : 1)};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
`;

export const FooterWrapper = styled(HeaderWrapper)`
    padding: ${({ theme: { base } }) =>
        `${base.spacingInContent} ${base.spacingSmall} ${base.spacing} ${base.spacingSmall}`};
`;

export const CloseIcon = styled.div`
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.text};
    line-height: 1;
`;
