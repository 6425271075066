import styled, { css } from "styled-components";

export const Block = styled.div`
    margin: ${({ theme: { base } }) => base.spacingSmall};
    padding: 32px;
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;

export const BlockTitle = styled.h2`
    margin: ${({ theme: { base } }) => base.spacingSmall} 0;
    font-size: ${({ theme: { base } }) => base.titleSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: 100;
    > span {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const BarsContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 300px;
    margin: ${({ theme: { base } }) => base.spacingSmall} -${({ theme: { base } }) => base.spacingTiny};
`;

export const BarBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 ${({ theme: { base } }) => base.spacingTiny};
`;

export const BarDuration = styled.div`
    margin-top: ${({ theme: { base } }) => base.spacingTiny};
    color: ${({ theme: { colors } }) => colors.text};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    text-align: center;
`;

export const Bar = styled.div<{ active?: boolean; height: number }>`
    height: ${({ height }) => height}%;
    padding: ${({ theme: { base } }) => base.spacingTiny};
    border-radius: ${({ theme: { base } }) => base.borderRadiusSmall};
    background-color: ${({ theme: { colors }, active = false }) => (active ? colors.primary : colors.backgroundMain)};
    color: ${({ theme: { colors }, active = false }) => (active ? colors.backgroundContrast : colors.text)};
    font-size: ${({ theme: { base } }) => base.mediumSize};
    text-align: center;
`;

export const modalCss = css`
    background-color: ${({ theme: { colors } }: any) => colors.backgroundMain};
`;

export const Arrow = styled.div`
    position: relative;
    height: 4px;
    margin-right: 4px;
    background-color: ${({ theme: { colors } }: any) => colors.border};

    &:before {
        content: " ";
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: 0;
        width: 4px;
        background-color: ${({ theme: { colors } }: any) => colors.border};
    }

    &:after {
        content: " ";
        position: absolute;
        right: -4px;
        top: -6px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid ${({ theme: { colors } }: any) => colors.border};
    }
`;

export const Description = styled.p`
    margin: ${({ theme: { base } }) => base.spacingInContent} 0 ${({ theme: { base } }) => base.spacing} 0;
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const DescriptionNextPracticeDate = styled.p`
    margin: ${({ theme: { base } }) => base.spacingSmall} 0;
    font-size: ${({ theme: { base } }) => base.mediumSize};
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    font-weight: 100;
    line-height: 1.5;
    > span {
        color: ${({ theme: { colors } }) => colors.primary};
    }
`;

export const Button = styled.button`
    width: 100%;
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    font-size: ${({ theme: { base } }) => base.bigSize};
    color: white;
    cursor: pointer;
    opacity: 1;
`;

export const ButtonWrapper = styled.div`
    margin: ${({ theme: { base } }) => base.spacingSmall};
`;
