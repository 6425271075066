// REACT
import React, {
    FunctionComponent,
    Children,
    isValidElement,
    cloneElement,
    useState,
    useEffect,
    ReactElement,
    CSSProperties,
} from "react";
import { createPortal } from "react-dom";

// STYLED COMPONENTS
import * as Styled from "./styles";

export type WithModalActions<OtherProps = {}> = OtherProps & {
    modalActions?: {
        hide: () => void;
        show: () => void;
    };
};

export type Props = {
    children: React.ReactNode;
    active: boolean;
    title?: ReactElement | string;
    headerComponent?: false | ((props: WithModalActions<{ title?: any }>) => ReactElement | null);
    footerComponent?: false | ((props: WithModalActions<{ title?: any }>) => ReactElement | null);
    scrollContent?: boolean;
    preventCloseOnBg?: boolean;
    destroyOnClose?: boolean;
    provideActions?: boolean;
    beforeClose?: () => boolean | void;
    afterClose?: () => void;
    style?: any;
    modalBodyStyle?: CSSProperties;
    modalBodyCss?: any; // result of css from styled-component
    modalBodyClass?: string;
};

const DefaultHeader: FunctionComponent<WithModalActions<{ title?: any }>> = ({ title, modalActions }) => (
    <Styled.ModalHeader>
        {title || ""}
        <Styled.CloseIcon
            aria-label="Close"
            onClick={modalActions?.hide}
            name={"close"}
        />
    </Styled.ModalHeader>
);

const Modal: FunctionComponent<Props> = (props) => {
    const {
        children,
        active,
        title,
        headerComponent = DefaultHeader,
        footerComponent = false,
        scrollContent = false,
        preventCloseOnBg = false,
        destroyOnClose = false,
        provideActions = false,
        beforeClose = () => {},
        afterClose = () => {},
        modalBodyStyle,
        modalBodyCss,
        modalBodyClass,
        ...otherProps
    } = props;
    const [isActive, activeState] = useState(false);
    const [isDestroyed, destroyState] = useState(false);

    const hide = () => {
        if (beforeClose() === false) return;
        activeState(false);
        setTimeout(() => {
            if (destroyOnClose) destroyState(true);
            afterClose();
        }, 300);
    };

    const show = () => {
        destroyState(false);
        activeState(true);
    };

    useEffect(() => {
        const action = active ? show : hide;
        if (active !== isActive) action();
        // eslint-disable-next-line
    }, [active]);

    return createPortal(
        <Styled.FixedWrapper
            className={isActive ? "active" : ""}
            scroll={!scrollContent}
        >
            {!isDestroyed && (
                <Styled.ModalWrapper>
                    <Styled.Background onClick={preventCloseOnBg ? undefined : hide} />
                    <Styled.ModalBody
                        style={modalBodyStyle}
                        css={modalBodyCss}
                        className={modalBodyClass}
                    >
                        {!!headerComponent &&
                            headerComponent({
                                title,
                                modalActions: {
                                    hide,
                                    show,
                                },
                            })}
                        <Styled.ModalContent
                            scroll={scrollContent}
                            {...otherProps}
                        >
                            {!provideActions
                                ? children
                                : Children.map(children, (child) =>
                                      !isValidElement(child)
                                          ? child
                                          : cloneElement(child, {
                                                modalActions: {
                                                    hide,
                                                    show,
                                                },
                                            })
                                  )}
                        </Styled.ModalContent>
                        {!!footerComponent &&
                            footerComponent({
                                modalActions: {
                                    hide,
                                    show,
                                },
                            })}
                    </Styled.ModalBody>
                </Styled.ModalWrapper>
            )}
        </Styled.FixedWrapper>,
        document.body
    );
};

export default Modal;
