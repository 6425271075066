import styled from "styled-components";
import { Col, Row } from "../../../basic/grid";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    font-size: ${({ theme: { base } }) => base.size};
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const Title = styled.h2`
    margin-top: 20px;
    margin-bottom: 0;
    font-size: ${({ theme: { base } }) => base.iconSmall};
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
`;

export const IconWrapper = styled.div`
    margin-top: 20px;
    font-size: 140px;
    color: ${({ theme: { colors } }) => colors.text};
    text-align: center;
    & > * {
        font-size: inherit;
    }
`;

export const ScoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    > p {
        font-size: ${({ theme: { base } }) => base.extraBigSize};
    }
`;

export const ButtonWrapper = styled(Row)`
    margin: 0 !important;
`;

export const PlayAgainCol = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

export const RightButtonWrapper = styled(Col)`
    margin-right: 12px;
`;

export const LeftButtonWrapper = styled(Col)`
    margin-left: 12px;
`;

export const ModalButton = styled.button<{
    outline?: boolean;
    disabled?: boolean;
    primary?: boolean;
    fullWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    min-height: 40px;
    ${({ fullWidth = false }) => (fullWidth ? "width: 100%;" : "")}
    min-width: 100px;
    padding: ${({ theme: { base } }) => base.spacingInContent + " " + base.spacingSmall};
    font-size: 18px;
    color: ${({ theme: { colors }, primary = false }) => (primary ? "#fff" : colors.text)};
    text-align: center;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    border: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (!outline) return "none";
        return `1px solid ${primary ? colors.primary : colors.backgroundMain}`;
    }};
    background: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (outline) return "none";
        return primary ? colors.primary : colors.backgroundMain;
    }};
    cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
    ${({ disabled = false, theme: { base } }) =>
        disabled
            ? "opacity: 0.5;"
            : `
        &:hover {
            opacity: ${base.hoverOpacity};
        }
    `}
`;

export const ShareModalButton = styled(ModalButton)`
    padding: ${({ theme: { base } }) => base.spacingTiny + " " + base.spacingSmall};
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
