//LIBRARIES
import React, { FunctionComponent, useCallback, ReactNode } from "react";
import { useT } from "@transifex/react";

//STYLED COMPONENTS
import * as Styled from "../../../../basic/modal/styles";
import { Background, Content, Description, ButtonsContainer, Button } from "./styles";

//TYPES
import { GlobalModalProps } from "../../../../../helpers/Modal";

interface ModifyCardProps extends GlobalModalProps {
    title: string;
    confirmButtonText: string;
    descriptionWarning: ReactNode;
    additionalNotice?: ReactNode;
    onClickMethod: () => void;
}

const DefaultHeader: FunctionComponent<{ title: string; close: () => any }> = ({ title, close }) => (
    <Styled.ModalHeader error>
        <Styled.Title>{title.toUpperCase() || ""}</Styled.Title>
        <Styled.CloseIcon
            onClick={close}
            name="close"
        />
    </Styled.ModalHeader>
);

const ModifyCard: React.FC<ModifyCardProps> = (props) => {
    const t = useT();

    const cancel_t = t("Cancel", { _tags: "modal,button" });

    const { title, confirmButtonText, descriptionWarning, additionalNotice = false, onClickMethod, close } = props;
    const handleClose = useCallback(() => {
        close();
    }, [close]);

    return (
        <Background>
            <DefaultHeader
                title={title}
                close={handleClose}
            />
            <Content>
                <Description>{descriptionWarning}</Description>
                {additionalNotice && <Description>{additionalNotice}</Description>}
            </Content>
            <ButtonsContainer>
                <Button onClick={handleClose}>{cancel_t}</Button>
                <Button
                    onClick={onClickMethod}
                    highlighted
                >
                    {confirmButtonText}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default ModifyCard;
