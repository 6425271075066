//LIBRARIES
import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

//REDUX
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { selectors, actions } from "../../../redux/subjects/subjectsSlice";
import { SubjectData, SubjectUnitCard } from "p6m-subjects";

//NETWORK
import { getSubjectUnitCards } from "../../../networking/cards";

//HOOKS
import { useRequest } from "../../../hooks/useRequest";

//TYPES
import {TResult} from "../../../views/activation";

//COMPONENTS
import Component from "./ActivationList";
import { getCardContentInfo } from "../../../helpers/Cards";

export type ActivationListProps = {
    isPrepareForTest?: boolean;
    subjectId: string;
    cards?: SubjectUnitCard[];
    onChange: (result: TResult) => void;
};

export const ActivationList: FunctionComponent<ActivationListProps> = ({
    isPrepareForTest = false,
    subjectId,
    cards: selectedCards = [],
    onChange,
}) => {
    const [searchString, setSearch] = useState("");
    const [selectedCardIdsByUnitIds, setSelectedCardIdsByUnitIds] = useState<{ [key: string]: string[] }>(
        selectedCards.reduce((acc, card) => {
            const unitId = card.unitIdToOwner.id;
            if (!acc[unitId]) {
                acc[unitId] = [];
            }
            acc[unitId] = [...acc[unitId], card.cardIdToOwner.id];
            return acc;
        }, {} as { [key: string]: string[] })
    );

    const [cards, setCards] = useState<SubjectUnitCard[]>([]);
    const allSelectedCardIds = useMemo(
        () => Object.values(selectedCardIdsByUnitIds).flat() as string[],
        [selectedCardIdsByUnitIds]
    );

    const userMetadata = useSelector(userSelectors.userMetadata);
    const { units } = useSelector(selectors.getSubjectById(subjectId)) || ({} as SubjectData);

    const dispatch = useDispatch();

    const fetchCards = useRequest(getSubjectUnitCards);

    useEffect(() => {
        if (units) return;
        dispatch(actions.loadSubjectUnits(subjectId));
    }, [units, subjectId, dispatch]);

    useEffect(() => {
        if (!units?.length) return;
        const unitsId = units.map((unit) => unit.unitId.id);

        const filters: Parameters<typeof getSubjectUnitCards>[0]["filterMode"][] = ["LEARN_NEW", "LIBRARY"];

        const filterMode = filters[+isPrepareForTest];

        fetchCards({
            subjectId,
            units: unitsId,
            filterMode: filterMode,
        }).then((result) => {
            const {
                data: {
                    replyContent: { cards },
                },
            } = result;
            setCards(cards);
        });
    }, [units, subjectId, isPrepareForTest, fetchCards]);

    const searchedCards = useMemo(() => {
        if (!searchString) return cards;
        return cards.filter((card) => {
            const {
                cardContent: { question },
            } = card;
            return question.includes(searchString);
        });
    }, [searchString, cards]);

    useEffect(() => {
        if (!units || !searchedCards?.length) return;
        if (!allSelectedCardIds.length) {
            return onChange({
                cards: [],
            });
        }
        const searchedAndSelectedCards = searchedCards.filter(({ cardIdToOwner: { id } }) =>
            allSelectedCardIds.includes(id)
        );
        if (!searchedAndSelectedCards?.length) return;

        onChange({
            cards: searchedAndSelectedCards,
        });
    }, [allSelectedCardIds, units, searchedCards, onChange]);

    const resultUnits: any = useMemo(() => {
        if (!units?.length) return [];
        return units.map((unit) => {
            const {
                unitId: { id },
                unitContent: { name: title, order },
                cardCount: count,
            } = unit;
            return {
                id,
                title,
                count,
                order,
            };
        });
    }, [units]);

    const resultCards: any = useMemo(() => {
        if (!searchedCards.length) return [];
        return searchedCards.map((card) => {
            const {
                cardContent: {
                    question,
                    order,
                    unitIdToOwner: { id: unitId },
                },
                cardIdToOwner: { id },
            } = card;
            return {
                id,
                unitId,
                title: getCardContentInfo(question, "title"),
                order,
            };
        });
    }, [searchedCards]);

    const handleSelection = useCallback((items: string[], unitId: string, checked: boolean) => {
        setSelectedCardIdsByUnitIds((oldMap) => {
            const newUnitCardsSelectionMap = { ...oldMap };
            if (!newUnitCardsSelectionMap[unitId]) {
                newUnitCardsSelectionMap[unitId] = [];
            }
            if (checked) {
                newUnitCardsSelectionMap[unitId] = Array.from(new Set([...newUnitCardsSelectionMap[unitId], ...items]));
            } else {
                const filteredIds = newUnitCardsSelectionMap[unitId].filter((prevId) => !items.includes(prevId));
                newUnitCardsSelectionMap[unitId] = filteredIds;
            }
            return newUnitCardsSelectionMap;
        });
    }, []);

    return (
        <Component
            onSearch={setSearch}
            selectedCardIdsByUnitIds={selectedCardIdsByUnitIds}
            units={resultUnits}
            cards={resultCards}
            firstPracticeFinished={userMetadata.hasFirstPractice}
            searchString={searchString}
            onSelect={handleSelection}
            isPrepareForTest={isPrepareForTest}
        />
    );
};
