import React, { FunctionComponent, useState, useMemo, useCallback } from "react";
import { useT } from "@transifex/react";
import { useSelector, useDispatch } from "react-redux";

//REDUX
import { selectors as subjectSelectors, actions as subjectActions } from "../../../../redux/subjects/subjectsSlice";
import { selectors as userSelectors } from "../../../../redux/user/userSlice";
import { selectors, actions } from "../../../../redux/add/addSlice";

//TYPES
import { SubjectData } from "p6m-subjects";

//COMPONENTS
import Component, { Props as ComponentProps } from "./SubjectsMenu";

//UTILS
import { generateSubjectTemplate, isDefaultSubject } from "../../../../helpers/Subjects";
import { useSelectFirstItem } from "../../../../hooks/useSelectFirstItem";

type Props = {
    onToggle?: ComponentProps['onToggle'];
};

export const SubjectsMenu: FunctionComponent<Props> = (props) => {
    const { onToggle } = props;
    const subjectId = useSelector(selectors.getAddCardsSubjectId);
    const userDnsId = useSelector(userSelectors.userId);
    const subjects = useSelector(subjectSelectors.librarySubjects) || [];
    const [deleteCardsCount, setDeleteCardsCount] = useState<number>(0);
    const dispatch = useDispatch();

    const defaultSubject = useGetDefaultSubject();

    const subjectsToShow = useMemo(
        () => (subjects.length ? transformSubjects(subjects, userDnsId) : [defaultSubject]),
        [subjects, userDnsId, defaultSubject]
    );

    const subjectKeys = useMemo(() => subjectsToShow.map(({ key }) => key), [subjectsToShow]);

    const selectKey = useCallback((key: string) => dispatch(actions.setCardSubjectId(key)), [dispatch]);

    useSelectFirstItem(subjectKeys, subjectId, selectKey);

    const findSubject = useCallback(
        (key: string) => {
            return subjects.find((subject: SubjectData) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { id },
                    },
                } = subject;

                return key === id;
            });
        },
        [subjects]
    );

    const onSelect = useCallback(
        (selected: Required<ComponentProps>["items"][0]) => {
            dispatch(actions.setCardSubjectId(selected.key as string));
        },
        [dispatch]
    );

    const onSaveCallback = useCallback(
        (data) => {
            const dataMapRelation: any = {
                deleted: ({ key }: { key: string }) => {
                    const subject = findSubject(key);
                    if (!subject) return undefined;

                    const {
                        subjectMetadata: {
                            subjectIdToOwner: { id: subjectId, ownerId },
                        },
                    } = subject;

                    return generateSubjectTemplate({
                        subjectId,
                        creatorId: ownerId,
                        name: subject.subjectContent.name,
                    });
                },
                updated: ({ key, value }: { key: string; value: string }) => {
                    const subject = findSubject(key);
                    if (!subject) return undefined;

                    const {
                        subjectMetadata: {
                            subjectIdToOwner: { id: subjectId, ownerId },
                        },
                    } = subject;

                    return generateSubjectTemplate({ subjectId, creatorId: ownerId, name: value });
                },
                created: (value: string) =>
                    generateSubjectTemplate({ creatorId: userDnsId!, name: value, withDefaultUnit: true }),
            };

            const result: any = {};

            Object.entries(data).forEach(([key, values = []]: any) => {
                result[key] = values.map((value: any) => dataMapRelation[key](value));
            });

            if (result.created && result.created.length) {
                dispatch(actions.setCardSubjectId(result.created[0].subjectId));
            }

            dispatch(subjectActions.complexSubjectsRest(result));
        },
        [dispatch, userDnsId, findSubject]
    );

    const onDeleteCallback = useCallback(
        (item: any) => {
            const { key, deleted } = item;
            if (!key) return;

            const subject = findSubject(key);
            if (!subject) return;

            const { allCardsCount = 0 } = subject;

            if (!allCardsCount) return;

            const method = [
                (currentCount: number) => currentCount - allCardsCount,
                (currentCount: number) => currentCount + allCardsCount,
            ][Number(deleted)];

            setDeleteCardsCount((currentCount) => method(currentCount));
        },
        [findSubject]
    );

    const onCloseEditCallback = useCallback(() => {
        setDeleteCardsCount(0);
    }, []);

    return (
        <Component
            items={subjectsToShow}
            selected={subjectId}
            onSelect={onSelect}
            editItems={subjectsToShow}
            onSave={onSaveCallback}
            onDelete={onDeleteCallback}
            deleteCardsCount={deleteCardsCount}
            onCloseEdit={onCloseEditCallback}
            onToggle={onToggle}
        />
    );
};

function transformSubjects(subjects: SubjectData[], userDnsId?: string) {
    return subjects.map((subject) => {
        const {
            subjectContent: { name },
            subjectMetadata: {
                subjectIdToOwner: { id, ownerId },
                classroomFlag = false,
            },
        } = subject;

        const isMySubject: boolean = ownerId === userDnsId;
        const isDefault: boolean = isDefaultSubject(subject);

        return {
            component: name,
            value: name,
            key: id,
            notDeletable: classroomFlag || isDefault,
            notEditable: !isMySubject || isDefault,
        };
    });
}

function useGetDefaultSubject() {
    const t = useT();
    const t_defaultSubject: string = t("General", {});

    return useMemo(
        function () {
            return {
                component: t_defaultSubject,
                value: t_defaultSubject,
                key: "default",
                notDeletable: true,
                notEditable: true,
            };
        },
        [t_defaultSubject]
    );
}
