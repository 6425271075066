import React, { useEffect, useState, useRef, useCallback } from "react";
import { useT } from "@transifex/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../redux/add/addSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as subjectActions, selectors as subjectSelectors } from "../../redux/subjects/subjectsSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";
import ResponseConstants from "../../constants/ResponseConstants";
import { useRequest } from "../../hooks/useRequest";
import { getWordDetailed } from "../../networking/dictionary";
import { isSupported } from "../../helpers/Dictionary";
import { IResponse } from "p6m-response";
import { DictionaryWordResults } from "p6m-dictionary";
import { AxiosResponse } from "axios";
import { tabListener } from "../../helpers/TabListener";
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

import Component from "./Add";

const AddWrapper: React.FC = () => {
    const { cardId: cardIdFromUrl, ownerId: ownerIdFromUrl } = useParams<{ cardId?: string; ownerId?: string }>();
    const userId = useSelector(userSelectors.userId);
    const [questionRef, setQuestionRef] = useState<any>();
    const [answerRef, setAnswerRef] = useState<any>();
    const [isDropdownsOpenCounter, setDropdownsOpenCounter] = useState<number>(0);

    const modal = useSelector(selectors.getAddCardModal);
    const isSaving = useSelector(selectors.isSaving);

    useAppVersionCheck();

    const element = useRef<HTMLDivElement>();
    const t = useT();

    const t_canAddAnnotations = t("You can add an annotation to both, the question and the answer.", {});
    const t_publisherContentCanNotBeModified = t(
        "Direct modification of cards from bought publisher contents is not possible.",
        {}
    );

    const dispatch = useDispatch();

    const isMyCard: boolean = !ownerIdFromUrl || !cardIdFromUrl || userId === ownerIdFromUrl;

    useLoadData(isMyCard);

    // use effect to start creating card
    useEffect(
        function () {
            if (ownerIdFromUrl && cardIdFromUrl) return;

            dispatch(actions.setCardId(undefined)); // clear cardId if it exists
            return () => {
                dispatch(actions.clearAddCardContents()); // clear saved content
            };
        },
        [dispatch, cardIdFromUrl, ownerIdFromUrl]
    );

    // use effect to start edit card and fetch all info
    useEffect(() => {
        if (!ownerIdFromUrl || !cardIdFromUrl) return;

        dispatch(
            actions.fetchCardContent({
                ownerId: ownerIdFromUrl,
                cardId: cardIdFromUrl,
            })
        );

        return function () {
            // clear all persisted snapshot if edit card finished
            dispatch(actions.clearAddCardsSnapshot());
        };
    }, [dispatch, cardIdFromUrl, ownerIdFromUrl]);

    // use effect for non editable card (if it isn't your card)
    useEffect(
        function () {
            if (isMyCard) return;

            dispatch(
                responseActions.showResponse({
                    type: ResponseConstants.WARNING,
                    text: [t_canAddAnnotations, t_publisherContentCanNotBeModified],
                })
            );
        },
        [dispatch, isMyCard, t_canAddAnnotations, t_publisherContentCanNotBeModified]
    );

    useAutoCpmplete(answerRef, questionRef);

    // use to add tab click switch only elements with tabindex inside element ref
    useEffect(() => {
        const parentElement = element.current;
        const destroyTabListener = tabListener(parentElement);
        return function () {
            destroyTabListener();
        };
    }, [element]);

    const onDropdownToggleCallback = useCallback((state: boolean) => {
        const counterChange: number = state ? 1 : -1;
        setDropdownsOpenCounter((prevState: number) => prevState + counterChange);
    }, []);

    return (
        <Component
            ref={(refElement) => (element.current = refElement || undefined)}
            getQuestionRef={setQuestionRef}
            getAnswerRef={setAnswerRef}
            focusQuestion={!modal && !isSaving && isDropdownsOpenCounter <= 0}
            onDropdownToggle={onDropdownToggleCallback}
        />
    );
};

export default AddWrapper;

function useAutoCpmplete(answerRef: any, questionRef: any) {
    const primaryLang = useSelector(selectors.getAddCardsPrimaryLang);
    const secondaryLang = useSelector(selectors.getAddCardsSecondaryLang);
    const hasPremium = useSelector(userSelectors.userHasPremium);

    const getWordDetailedRequest = useRequest(getWordDetailed, true);

    useEffect(() => {
        if (!hasPremium) return;
        if (!answerRef || !questionRef) return;
        if (!primaryLang || !secondaryLang) return;
        if (!isSupported({ primaryLang, secondaryLang })) return;

        const { current } = questionRef;
        const editor = current.getEditor();
        const { current: answCurrent } = answerRef;
        const answerEditor = answCurrent.getEditor();

        const removeAnswerAutocomplete = registerAnswerAutocomplete(
            editor,
            answerEditor,
            primaryLang,
            secondaryLang,
            getWordDetailedRequest
        );

        return () => {
            if (removeAnswerAutocomplete) removeAnswerAutocomplete();
        };
    }, [answerRef, questionRef, primaryLang, secondaryLang, hasPremium, getWordDetailedRequest]);
}

function registerAnswerAutocomplete(
    questionEditor: any,
    answerEditor: any,
    pL: string,
    sL: string,
    request: typeof getWordDetailed
): () => void {
    const questionElement = questionEditor.root as HTMLInputElement;
    const answerElement = answerEditor.root as HTMLInputElement;

    const targetLang = [pL, sL].find((lang) => lang !== "de");

    const memoRequest = (function () {
        const results: Record<string, string> = {};
        let promise: Promise<any> | undefined = undefined;

        return function () {
            const text: string = questionEditor.getText();
            const length: number = text.split(" ").filter((word) => !!word).length;

            if (answerEditor.getLength() > 1 || text.length <= 1 || !targetLang || length > 2) {
                return Promise.resolve();
            }

            if (text in results) return Promise.resolve(results[text]);
            if (promise) return promise; // return if is in progress

            promise = request(text, "de", targetLang).then(function (response) {
                const result = getResponseResult(response, pL);
                results[text] = result;
                promise = undefined;
                return result;
            });

            return promise;
        };
    })();

    const onBlurListener = function () {
        memoRequest();
    };
    questionElement.addEventListener("blur", onBlurListener);

    const onFocusListener = function () {
        memoRequest().then(function (result) {
            if (!result) return;
            answerEditor.setText(result);
            const {
                selection: {
                    savedRange: { index = 0 },
                },
            } = answerEditor;
            answerEditor.setSelection(1 + index);
        });
    };
    answerElement.addEventListener("focus", onFocusListener);

    return function () {
        questionElement.removeEventListener("blur", onBlurListener);
        answerElement.removeEventListener("focus", onFocusListener);
    };
}

function getResponseResult(response: AxiosResponse<IResponse<DictionaryWordResults>>, pL: string): string {
    const {
        data: {
            replyContent: { languages = [] },
        },
    } = response;
    const language = languages.find(({ language }: any) => language === pL);
    if (!language || !language.sortedClasses) return "";
    const key = language.sortedClasses[0];
    if (!key) return "";
    const examples = language.wordClasses[key].find(({ examples = [] }) => !!examples.length)?.examples || [];
    const translation = examples[0]?.target;
    return translation || "";
}

function useLoadData(isMyCard: boolean) {
    const subjectId = useSelector(selectors.getAddCardsSubjectId);
    const subjects = useSelector(subjectSelectors.librarySubjects);
    const units = useSelector(subjectSelectors.getUnitsBySubjectId(subjectId || "", "librarySubjects"));
    const subjectsLoaded = useRef<boolean>(false);
    const dispatch = useDispatch();

    useEffect(
        function () {
            if (subjectsLoaded.current) return;
            if (!isMyCard) return;
            subjectsLoaded.current = true;
            dispatch(subjectActions.loadLibrarySubjects());
        },
        [dispatch, subjects, isMyCard]
    );

    useEffect(
        function () {
            if (!isMyCard) return;
            if (units || !subjectId || subjectId === "default") return;
            dispatch(subjectActions.loadSubjectUnits({ subjectId, target: "librarySubjects" }));
        },
        [dispatch, subjectId, units, isMyCard]
    );
}
