import styled from "styled-components";

export const Title = styled.span`
    text-transform: uppercase;
`;

export const ContentContainer = styled.div`
    padding: ${(props) => props.theme.base.spacing};
    background-color: #fff;
    min-height: 250px;
`;

export const QATitle = styled.h3`
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    font-size: ${({ theme: { base } }) => base.bigSize};
    font-weight: normal;
    text-transform: uppercase;
`;

export const ModalFooter = styled.div`
    padding: ${({ theme: { base } }) => base.spacing};
`;

export const ModalButton = styled.button<{
    outline?: boolean;
    disabled?: boolean;
    primary?: boolean;
    fullWidth?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    min-height: 30px;
    ${({ fullWidth = false }) => (fullWidth ? "width: 100%;" : "")}
    min-width: 100px;
    padding: ${({ theme: { base } }) => base.borderRadius + " " + base.spacingSmall};
    font-size: 14px;
    color: ${({ theme: { colors }, primary = false }) => (primary ? "#fff" : colors.text)};
    text-align: center;
    text-transform: uppercase;
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    border: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (!outline) return "none";
        return `1px solid ${primary ? colors.primary : "#f2f2f2"}`;
    }};
    background: ${({ outline = false, primary = false, theme: { colors } }) => {
        if (outline) return "none";
        return primary ? colors.primary : "#f2f2f2";
    }};
    cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
    ${({ disabled = false, theme: { base } }) =>
        disabled
            ? "opacity: 0.5;"
            : `
        &:hover {
            opacity: ${base.hoverOpacity};
        }
    `}
`;
