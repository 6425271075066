// LIBRARIES
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../../../redux/add/addSlice";
import { selectors as appStatusSelectors } from "../../../../redux/appStatus/appStatusSlice";
import { actions as modalActions } from "../../../../redux/modal/modalSlice";
import { ampli } from "../../../../ampli";
import { selectors as responseSelectors } from "../../../../redux/response/responseSlice";

//TYPES
import { HistoryState } from "p6m-routing";

// COMPONENTS
import Component from "../../../complex/addCards/addCardsButtons/AddCardsButtons";

// MODELS
import { GlobalModalView } from "../../../../helpers/Modal";

export const AddCardsButtons: FunctionComponent = () => {
    const response = useSelector(responseSelectors.response);
    const cardId = useSelector(selectors.getAddCardsCardId);
    const subjectId = useSelector(selectors.getAddCardsSubjectId);
    const errors = useSelector(selectors.validateSnapshot);
    const loading = useSelector(appStatusSelectors.isLoading);
    const hasSeenFirstCardReminder = useSelector(selectors.hasSeenFirstCardReminder);

    const areAnnotationsValid = useSelector(selectors.areAnnotationsValid);

    const [reminder, setReminder] = useState(false);
    const [cardIsCurrentlySaving, setCardIsCurrentlySaving] = useState(false);
    const [goBack, setGoBack] = useState<Boolean>(false);

    const history = useHistory<HistoryState>();
    const dispatch = useDispatch();

    const isEdit = !!cardId;
    const dontShowConfirmEdit = useSelector(selectors.getDontShowConfirmEditModal);

    const handleGoBack = useCallback(() => {
        setGoBack(true);
    }, []);

    const handleSave = useCallback(() => {
        setCardIsCurrentlySaving(true);
        if (errors.length) return;
        if (!isEdit) {
            dispatch(actions.saveCard());
            if (hasSeenFirstCardReminder) return;
            setReminder(true);
            return;
        }
        if (!dontShowConfirmEdit && areAnnotationsValid) {
            dispatch(modalActions.setModalView(GlobalModalView.ConfirmEdit));
            return;
        } else dispatch(actions.saveCard());

        if (!areAnnotationsValid) return;
        history.push(`/manage/${subjectId}`);
        ampli.clickedEditCardsButton({
            cards_number: 1,
            user_action: "editCard",
        });
    }, [
        errors.length,
        isEdit,
        dontShowConfirmEdit,
        dispatch,
        history,
        subjectId,
        hasSeenFirstCardReminder,
        areAnnotationsValid,
    ]);

    const afterCloseFirstCard = useCallback(() => {
        dispatch(actions.setFirstCardReminder());
        setReminder(false);
    }, [dispatch]);

    useEffect(() => {
        //redirect conditionally based on button-, modal- and responseAction-PopUp status
        if (goBack) {
            const responsePopUpIsShowing = response.responseMessage;
            if (responsePopUpIsShowing) {
                if (response.type === "ERROR") {
                    setGoBack(false);
                    return;
                }
                setCardIsCurrentlySaving(false);
            }
            if (!reminder && !cardIsCurrentlySaving) {
                const previousUrl = history.location.state?.from;
                previousUrl ? history.push(previousUrl) : history.goBack();
            }
        }
    }, [
        goBack,
        cardIsCurrentlySaving,
        response.type,
        response.responseMessage,
        history,
        reminder,
        hasSeenFirstCardReminder,
    ]);
    return (
        <Component
            isEdit={isEdit}
            errors={errors}
            loading={!!response.responseMessage || loading}
            firstCardModal={reminder}
            onGoBack={handleGoBack}
            onSave={handleSave}
            afterCloseFirstCard={afterCloseFirstCard}
        />
    );
};
