// LIBRARIES
import React, { useState, useCallback } from "react";
import { searchDictionary as searchDictionaryAPI } from "../../../networking/dictionary";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectors, actions } from "../../../redux/user/userSlice";

// COMPONENTS
import SearchInDictionary from "./SearchInDictionary";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { AvailableLanguageCodes, DictionarySearchResult, DictionarySearchResults } from "p6m-dictionary";
import { useHistory } from "react-router-dom";

const SubjectsDashboardWrapper: React.FC = () => {
    const history = useHistory();
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchResults, setSearchResults] = useState<Array<DictionarySearchResult>>([]);
    const [activePosition, setActivePosition] = useState<number>(0);

    const dictionarySearchLanguage = useSelector(selectors.dictionarySearchLanguage);
    const dispatch = useDispatch();

    const searchDictionary = useCallback(
        async (input: string) => {
            setSearchValue(input);
            setActivePosition(0);
            if (input) {
                try {
                    const response: AxiosResponse<IResponse<DictionarySearchResults>> = await searchDictionaryAPI(
                        input,
                        "de",
                        dictionarySearchLanguage
                    );
                    const {
                        data: { replyContent },
                    } = response;
                    setSearchResults(replyContent.words.slice(0, 5));
                } catch (e) {
                    console.log(e);
                    setSearchResults([]);
                }
            }
        },
        [dictionarySearchLanguage]
    );

    const increaseActiveIndex = () => {
        setActivePosition((prevActiveIndex) => Math.min(prevActiveIndex + 1, searchResults.length));
    };

    const decreaseActiveIndex = () => {
        setActivePosition((prevActiveIndex) => Math.max(prevActiveIndex - 1, 0));
    };

    const navigateToDictionary = (word: string) => {
        history.push(`/dictionary/${dictionarySearchLanguage}/${word}`);
    };

    const clearSearch = () => {
        setSearchValue("");
        setSearchResults([]);
    };

    const onLanguageSelect = (languageCode: AvailableLanguageCodes) => {
        dispatch(actions.setDictionaryLanguage(languageCode));
    };

    return (
        <SearchInDictionary
            searchValue={searchValue}
            handleChange={searchDictionary}
            clearSearch={clearSearch}
            searchResults={searchResults}
            dictionarySearchLanguage={dictionarySearchLanguage}
            onLanguageSelect={onLanguageSelect}
            activePosition={activePosition}
            increaseActiveIndex={increaseActiveIndex}
            decreaseActiveIndex={decreaseActiveIndex}
            navigateToDictionary={navigateToDictionary}
        />
    );
};

export default SubjectsDashboardWrapper;
