// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// REDUX
import { actions, selectors, selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as addActions, selectors as addSelectors } from "../../redux/add/addSlice";
import { actions as modalActions } from "../../redux/modal/modalSlice";

// COMPONENTS
import Dictionary from "./Dictionary";

// MODELS
import { User } from "p6m-user";
import {
    AvailableLanguageCodes,
    DictionarySearchResult,
    DictionarySearchResults,
    DictionaryWordResults,
    LanguageFilterTypes,
} from "p6m-dictionary";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { getWordDetailed, searchDictionary as searchDictionaryAPI } from "../../networking/dictionary";
import { useHistory, useParams } from "react-router-dom";
import DictionaryConstants from "../../constants/DictionaryConstants";
import { HistoryState } from "p6m-routing";

// HOOKS
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

// HELPERS
import { GlobalModalView } from "../../helpers/Modal";
import { getBuyPremiumUrl } from "../../helpers/Url";

let dictionarySearchValue = "";

const DictionaryWrapper: React.FC<{}> = () => {
    const globalUser: User = useSelector(userSelectors.user);
    const hasPremium: boolean = useSelector(userSelectors.userHasPremium);
    const isStrictMode = useSelector(userSelectors.isStrict);
    const dictionarySearchLanguage: AvailableLanguageCodes = useSelector(selectors.dictionarySearchLanguage);
    const sourceLanguageOfSelectedSubject = useSelector(addSelectors.getAddCardsPrimaryLang);

    const [activeIndexLanguageFilter, setActiveIndexLanguageFilter] = useState<LanguageFilterTypes>(
        DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingWordDetails, setLoadingWordDetails] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [searchResults, setSearchResults] = useState<Array<DictionarySearchResult>>([]);
    const [filteredSearchResults, setFilteredSearchResults] = useState<Array<DictionarySearchResult>>([]);
    const [wordDetails, setWordDetails] = useState<DictionaryWordResults | undefined>();
    const [activeWordClassIndex, setActiveWordClassIndex] = useState<number>(0);
    const [activeWordIndex, setActiveWordIndex] = useState<number>(0);
    const [activeExampleIndex, setActiveExampleIndex] = useState<number>(0);

    const history = useHistory<HistoryState>();
    const { language, word } = useParams<{ language: AvailableLanguageCodes; word: string }>();
    const dispatch = useDispatch();

    useAppVersionCheck();

    useEffect(() => {
        // if search language gets changed, reset language direction filter
        setActiveIndexLanguageFilter(DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang);
    }, [dictionarySearchLanguage]);

    useEffect(() => {
        let internalFilteredSearchResults = searchResults.filter((word) => {
            if (activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.GermanAndForeignLang) {
                return word.lang === "de" || dictionarySearchLanguage;
            } else if (activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.ForeignLang) {
                return word.lang === dictionarySearchLanguage;
            } else if (activeIndexLanguageFilter === DictionaryConstants.LanguageFilterTypes.GermanLang) {
                return word.lang === "de";
            }
            return false;
        });
        setFilteredSearchResults(internalFilteredSearchResults);
        setLoading(false);
    }, [searchResults, activeIndexLanguageFilter, dictionarySearchLanguage]);

    const searchDictionary = useCallback(
        debounce(async (input: string, internalDictionarySearchLanguage: AvailableLanguageCodes) => {
            dictionarySearchValue = input;
            setLoading(true);
            setSearchValue(input);
            setWordDetails(undefined);
            setActiveIndex(0);
            setActiveWordIndex(0);
            setActiveWordClassIndex(0);
            setActiveExampleIndex(0);
            if (input !== "") {
                try {
                    const response: AxiosResponse<IResponse<DictionarySearchResults>> = await searchDictionaryAPI(
                        input,
                        "de",
                        internalDictionarySearchLanguage
                    );
                    if (input === dictionarySearchValue) {
                        const {
                            data: { replyContent },
                        } = response;
                        setSearchResults(replyContent.words);
                        setLoading(false);
                    }
                    return;
                } catch (e) {
                    console.log(e);
                }
            }
            // set to empty list if no input or error in call
            setSearchResults([]);
        }, 100),
        []
    );

    useEffect(() => {
        if (dictionarySearchLanguage && word) {
            setLoadingWordDetails(true);
            setSearchValue(word);
            searchWordDetailed(word, dictionarySearchLanguage);
        }
    }, [dictionarySearchLanguage, word, searchDictionary]);

    useEffect(() => {
        if (language) {
            dispatch(actions.setDictionaryLanguage(language));
        }
    }, [language, dispatch]);

    const clearSearch = () => {
        setSearchValue("");
        setSearchResults([]);
        setWordDetails(undefined);
        history.push("/dictionary/" + dictionarySearchLanguage);
    };

    useEffect(() => {
        searchDictionary(searchValue, dictionarySearchLanguage);
    }, [searchValue, searchDictionary, dictionarySearchLanguage]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleKeyPressed = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "ArrowDown") {
            if (wordDetails) {
                // go to next example if exists
                if (
                    wordDetails.languages[0].wordClasses[wordDetails.languages[0].sortedClasses[activeWordClassIndex]][
                        activeWordIndex
                    ].examples[activeExampleIndex + 1]
                ) {
                    setActiveExampleIndex(activeExampleIndex + 1);
                }
                // go to next word if exists
                else if (
                    wordDetails.languages[0].wordClasses[wordDetails.languages[0].sortedClasses[activeWordClassIndex]][
                        activeWordIndex + 1
                    ]
                ) {
                    setActiveExampleIndex(0);
                    setActiveWordIndex(activeWordIndex + 1);
                }
                // go to next word class if exists
                else if (wordDetails.languages[0].sortedClasses[activeWordClassIndex + 1]) {
                    setActiveExampleIndex(0);
                    setActiveWordIndex(0);
                    setActiveWordClassIndex(activeWordClassIndex + 1);
                }
            } else {
                setActiveIndex(Math.min(activeIndex + 1, searchResults.length - 1));
            }
        }
        if (event.key === "ArrowUp") {
            if (wordDetails) {
                // go to previous example if exists
                if (activeExampleIndex > 0) {
                    setActiveExampleIndex(activeExampleIndex - 1);
                }
                // go to last example of previous word if exists
                else if (activeWordIndex > 0) {
                    setActiveExampleIndex(
                        wordDetails.languages[0].wordClasses[
                            wordDetails.languages[0].sortedClasses[activeWordClassIndex]
                        ][activeWordIndex - 1].examples.length - 1
                    );
                    setActiveWordIndex(activeWordIndex - 1);
                }
                // go to last word and last example of previous word class if exists
                else if (wordDetails.languages[0].sortedClasses[activeWordClassIndex - 1]) {
                    let newActiveWordIndex =
                        wordDetails.languages[0].wordClasses[
                            wordDetails.languages[0].sortedClasses[activeWordClassIndex - 1]
                        ].length - 1;
                    setActiveWordIndex(newActiveWordIndex);
                    setActiveExampleIndex(
                        wordDetails.languages[0].wordClasses[
                            wordDetails.languages[0].sortedClasses[activeWordClassIndex - 1]
                        ][newActiveWordIndex].examples.length - 1
                    );
                    setActiveWordClassIndex(activeWordClassIndex - 1);
                }
            } else {
                setActiveIndex(Math.max(activeIndex - 1, 0));
            }
        }
        if (event.key === "Enter" && searchResults[activeIndex] && searchResults[activeIndex].word) {
            history.push("/dictionary/" + dictionarySearchLanguage + "/" + searchResults[activeIndex].word);
            addWordToCards();
        }
    };

    const searchWordDetailed = async (word: string, searchLanguage: AvailableLanguageCodes) => {
        const response: AxiosResponse<IResponse<DictionaryWordResults>> = await getWordDetailed(
            word,
            "de",
            searchLanguage
        );
        const {
            data: { replyContent },
        } = response;
        setWordDetails(replyContent);
    };

    useEffect(() => {
        setLoadingWordDetails(false);
    }, [wordDetails]);

    const exampleClicked = (wordClassIndex: number, wordIndex: number, exampleIndex: number) => {
        setActiveExampleIndex(exampleIndex);
        setActiveWordIndex(wordIndex);
        setActiveWordClassIndex(wordClassIndex);
    };

    const addWordToCards = () => {
        if (wordDetails) {
            const activeWordClass = wordDetails.languages[0].sortedClasses[activeWordClassIndex];
            const activeExample =
                wordDetails.languages[0].wordClasses[activeWordClass][activeWordIndex].examples[activeExampleIndex];

            const sourceLanguageOfDictionaryEntry = wordDetails.languages[0].language;

            if (sourceLanguageOfDictionaryEntry === sourceLanguageOfSelectedSubject) {
                dispatch(addActions.setAnswerText(activeExample.target));
                dispatch(addActions.setQuestionText(activeExample.source));
            } else {
                dispatch(addActions.setAnswerText(activeExample.source));
                dispatch(addActions.setQuestionText(activeExample.target));
            }
            //we need to pass previous base-url so we can redirect back to dictionary from addCard-view
            history.push({ pathname: "/add", state: { from: "/dictionary" } });
        }
    };

    const handleGoToShop = () => {
        if (isStrictMode) {
            dispatch(modalActions.setModalView(GlobalModalView.StrictPremium));
        } else {
            window.open(getBuyPremiumUrl());
        }
    };

    return (
        <Dictionary
            user={globalUser}
            hasPremium={hasPremium}
            onGoToShop={handleGoToShop}
            searchValue={searchValue}
            handleChange={handleChange}
            handleKeyPressed={handleKeyPressed}
            onResultClicked={(word: string) => history.push("/dictionary/" + dictionarySearchLanguage + "/" + word)}
            clearSearch={clearSearch}
            searchResults={filteredSearchResults}
            loading={loading || loadingWordDetails}
            dictionarySearchLanguage={dictionarySearchLanguage}
            activeIndex={activeIndex}
            wordDetails={wordDetails}
            activeWordClassIndex={activeWordClassIndex}
            activeWordIndex={activeWordIndex}
            activeExampleIndex={activeExampleIndex}
            activeIndexLanguageFilter={activeIndexLanguageFilter}
            setActiveIndexLanguageFilter={setActiveIndexLanguageFilter}
            exampleClicked={exampleClicked}
            addWordToCards={addWordToCards}
        />
    );
};

export default DictionaryWrapper;
