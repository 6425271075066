import styled from "styled-components";
import SidneyBasic from "../../../assets/img/avatar/monster_basic.png";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { base } }) => base.spacingExtraBig};
    justify-content: center;
    align-items: center;
    padding: ${({ theme: { base } }) => base.spacingExtraBig};
    width: 100%;
    & .closeIcon {
        position: absolute;
        top: 0;
        right: 0;
        margin: ${({ theme: { base } }) => base.spacing};
        cursor: pointer;
    }
`;

export const InfoWrapper = styled.div<{ isStrict?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { base } }) => base.spacingBig};
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const Header = styled.h1`
    margin: 0;
    text-align: center;
    font-size: ${(props) => props.theme.base.titleExtraBigSize};
    font-weight: 400;
    display: flex;
    flex-direction: column;
    line-height: ${(props) => props.theme.base.titleExtraBigSize};
    & span {
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const SubHeader = styled.h3`
    margin: 0;
    text-align: center;
    font-size: ${(props) => props.theme.base.mediumSize};
    font-weight: 600;
    display: flex;
    flex-direction: column;
`;

export const BulletPointSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: ${(props) => props.theme.base.smallSize};
`;

export const BulletPoint = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: ${(props) => props.theme.base.mediumSize};
    gap: ${(props) => props.theme.base.spacingTiny};
    line-height: ${(props) => props.theme.base.mediumSize};
    & i {
        font-size: ${(props) => props.theme.base.titleSize};
        color: ${(props) => props.theme.colors.primary};
    }
`;

export const StrictModeInfo = styled.div`
    padding: ${({ theme: { base } }) => base.spacingInContent};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme: { base } }) => base.spacingTiny};
    font-size: ${({ theme: { base } }) => base.size};
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme: { base } }) => base.spacingInContent};
    width: 100%;
    > button {
        align-self: center;
        padding: ${({ theme: { base } }) => base.spacingSmall};
        font-size: ${({ theme: { base } }) => base.bigSize};
        font-weight: 600;
        cursor: pointer;
    }
`;

export const ConsumeButton = styled.button`
    width: 100%;
    max-width: 300px;
    background: ${({ theme: { colors } }) => colors.primary};
    border: 1px solid ${({ theme: { colors } }) => colors.primary};
    border-radius: calc(${({ theme: { base } }) => base.borderRadiusBig} * 2);
    color: ${({ theme: { colors } }) => colors.backgroundContrast};
    justify-content: center;
    align-items: center;
`;

export const CancelButton = styled.button`
    background: transparent;
    border: none;
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const DontShowAgainButton = styled.button`
    background: transparent;
    border: none;
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.text};
`;

export const Sidney = styled.div`
    border-radius: 50%;
    background-color: ${({ theme: { colors } }) => colors.backgroundMain};
    background-image: url(${SidneyBasic});
    background-position: center -20px;
    background-repeat: no-repeat;
    background-size: auto 110%;
    width: 100%;
    max-width: 200px;
    aspect-ratio: 1/1;
`;
