import React, { FunctionComponent, HTMLAttributes } from "react";
import * as Styled from "./styles";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    close?: () => void;
    width?: number;
}

const GlobalModal: FunctionComponent<ModalProps> = ({ children, width, close, ...rest }) => {
    return (
        <Styled.FixedWrapper {...rest}>
            <Styled.ModalWrapper>
                <Styled.Background onClick={close} />
                <Styled.ModalBody width={width}>
                    <Styled.ModalContent>{children}</Styled.ModalContent>
                </Styled.ModalBody>
            </Styled.ModalWrapper>
        </Styled.FixedWrapper>
    );
};

export default GlobalModal;
