//DEPENDENCIES
import React, { FunctionComponent } from "react";
import { useT } from "@transifex/react";

//STYLE
import * as Styled from "../../basic/modal/styles";
import { Background, Content, DropdownContainer, Warning, ButtonsContainer, Button } from "./styles";

//TYPE
import { DropdownOption } from "p6m-viewData";
import { EntryChange, SubjectData } from "p6m-subjects";
import { GlobalModalProps } from "../../../helpers/Modal";

//COMPONENT
import DropdownSelection from "../../basic/dropdownSelection/DropdownSelection";

interface ChangeCardSubjectModalProps extends GlobalModalProps {
    allUserSubjects: SubjectData[];
    subjectsOptions: DropdownOption[];
    selectedSubjectId: string;
    unitOptions: DropdownOption[];
    selectedUnitId: string;
    changeSubject: (subjectIds: string[]) => void;
    changeUnit: (unitIds: string[]) => void;
    save: () => void;
    deleteSubject: (subjectId: string) => void;
    changeSubjectEdit: (index: number, name: string) => void;
    resetSubjectEdit: () => void;
    saveSubjectEdit: (changes: EntryChange[]) => void;
    saveUnitEdit: (changes: EntryChange[]) => void;
    changeUnitEdit: (index: number, name: string) => void;
    resetUnitEdit: () => void;
    containsProtectedCards?: boolean;
}

const DefaultHeader: FunctionComponent<{ title: string; close: () => any }> = ({ title, close }) => (
    <Styled.ModalHeader>
        <Styled.Title>{title.toUpperCase() || ""}</Styled.Title>
        <Styled.CloseIcon
            onClick={close}
            name={"close"}
        />
    </Styled.ModalHeader>
);

const ChangeCardSubjectModal: React.FC<ChangeCardSubjectModalProps> = (props) => {
    const t = useT();

    const assign_t = t("Change assignment of selected cards", {
        _tags: "title, modal",
    });
    const subjectLabel = t("Subject", { _tags: "library,label" });
    const unitLabel = t("Unit", { _tags: "library,label" });
    const cancel_t = t("Cancel", { _tags: "library,button" });
    const ok_t = t("Ok", { _tags: "library,button" });
    const someCardsCanNotBeMoved_t = t("Some selected cards could not be moved because they are protected", {
        _tags: "library,message,warning",
    });

    const {
        selectedSubjectId,
        selectedUnitId,
        subjectsOptions,
        unitOptions,
        allUserSubjects,
        containsProtectedCards,
        changeSubject,
        changeSubjectEdit,
        resetSubjectEdit,
        saveSubjectEdit,
        changeUnit,
        changeUnitEdit,
        resetUnitEdit,
        saveUnitEdit,
        close,
        save,
    } = props;

    const firstUnitOptionId = unitOptions[0]?.value;
    const selectedUnitIds = [selectedUnitId! || firstUnitOptionId!];

    const forbiddenSubjectNames = allUserSubjects
        .filter((subject) =>
            subjectsOptions.every((option) => option.value !== subject.subjectMetadata.subjectIdToOwner.id)
        )
        .map((subject) => subject.subjectContent.name);

    return (
        <Background>
            <DefaultHeader
                title={assign_t}
                close={close}
            />
            <Content>
                <DropdownContainer>
                    <DropdownSelection
                        label={subjectLabel}
                        withBorder={true}
                        options={subjectsOptions}
                        multiSelection={false}
                        selectedIds={[selectedSubjectId]}
                        saveIds={changeSubject}
                        editable={true}
                        forbiddenNames={forbiddenSubjectNames}
                        onChange={changeSubjectEdit}
                        resetEdit={resetSubjectEdit}
                        saveEdit={saveSubjectEdit}
                        contentType="subjects"
                        displayDeleteWarning
                    />
                    <DropdownSelection
                        label={unitLabel}
                        withBorder={true}
                        options={unitOptions}
                        multiSelection={false}
                        selectedIds={selectedUnitIds}
                        saveIds={changeUnit}
                        editable={true}
                        onChange={changeUnitEdit}
                        resetEdit={resetUnitEdit}
                        saveEdit={saveUnitEdit}
                        contentType="units"
                        displayDeleteWarning
                    />
                </DropdownContainer>
                {containsProtectedCards && <Warning>{someCardsCanNotBeMoved_t}</Warning>}
            </Content>
            <ButtonsContainer>
                <Button onClick={close}>{cancel_t}</Button>
                <Button
                    onClick={save}
                    highlighted
                >
                    {ok_t}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default ChangeCardSubjectModal;
