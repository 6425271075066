// LIBRARIES
import React from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// COMPONENTS
import SSO from "../views/login/sso";
import Login from "../views/login";
import Home from "../views/home";
import Games from "../views/games/Games";
import Game2048 from "../views/games/Game2048/Game2048";
import { WordMatchList } from "../views/games/WordMatch/WordMatchList";
import { WordMatchGame } from "../views/games/WordMatch/WordMatchGame";
import Manage from "../views/manage";
import Add from "../views/add";
import Dictionary from "../views/dictionary";
import Reports from "../views/reports";
import Achievements from "../views/achievements";
import Response from "../components/connected/response";
import GlobalModal from "../views/globalModal";
import PrivateRoute from "../wrapper/privateRoute/PrivateRoute";
import ContentWithNav from "../wrapper/contentWithNav/ContentWithNav";
import Loading from "../components/connected/loading";
import Practice from "../views/practice";
import Activation from "../views/activation";
import Tests from "../views/tests/overview";
import TestDetails from "../views/tests/details";
import LeaderboardWrapper from "../views/leaderboard";
import GamesContextWrapper from "../context/games";
import LanguagesContextWrapper from "../context/languages";
import GoalsContextWrapper from "../context/goals";
import LogoutIframe from "../components/connected/logoutIframe";

//STYLES
import { Background } from "./styles";

export interface LayoutProps {
    theme: Object;
    backgroundImage?: any;
}

const Layout: React.FC<LayoutProps> = (props) => {
    const basename = document.querySelector("base")?.getAttribute("href") ?? "/";

    return (
        <ThemeProvider theme={props.theme}>
            <Background bgImage={props.backgroundImage}>
                <Loading data-qa="loader" />
                <Response />
                <LogoutIframe />
                <LanguagesContextWrapper>
                    <Router basename={basename}>
                        <GlobalModal />
                        <Switch>
                            <Route
                                exact
                                path={"/"}
                            >
                                <Login />
                            </Route>
                            <Route path={"/login/sso/:userId/:jossoId/:email?"}>
                                <SSO />
                            </Route>
                            <Route
                                exact
                                path={"/login"}
                            >
                                <Login />
                            </Route>
                            <PrivateRoute path={"/home"}>
                                <ContentWithNav>
                                    <Home />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/games"}>
                                <GamesContextWrapper>
                                    <ContentWithNav>
                                        <Switch>
                                            <PrivateRoute
                                                path={"/games"}
                                                exact
                                            >
                                                <Games />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path={"/games/2048"}
                                                exact
                                            >
                                                <Game2048 />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path={"/games/wordMatch"}
                                                exact
                                            >
                                                <WordMatchList />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path={"/games/wordMatch/:subjectId/:gameType"}
                                                exact
                                            >
                                                <WordMatchGame />
                                            </PrivateRoute>
                                        </Switch>
                                    </ContentWithNav>
                                </GamesContextWrapper>
                            </PrivateRoute>
                            <PrivateRoute path={"/manage/:subjectId/:searchText?"}>
                                <ContentWithNav>
                                    <Manage />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/manage"}>
                                <ContentWithNav>
                                    <Manage />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/add/:ownerId?/:cardId?"}>
                                <ContentWithNav>
                                    <Add />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/dictionary/:language?/:word?"}>
                                <ContentWithNav>
                                    <Dictionary />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/reports/:userId?"}>
                                <ContentWithNav showSmallDate={true}>
                                    <Reports />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/achievements"}>
                                <GoalsContextWrapper>
                                    <ContentWithNav>
                                        <Achievements />
                                    </ContentWithNav>
                                </GoalsContextWrapper>
                            </PrivateRoute>
                            <PrivateRoute path={"/leaderBoard"}>
                                <ContentWithNav>
                                    <LeaderboardWrapper />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/practice/:testId?"}>
                                <Practice />
                            </PrivateRoute>
                            <PrivateRoute
                                path={"/activation"}
                                exact
                            >
                                <Redirect to="/home" />
                            </PrivateRoute>
                            <PrivateRoute path={"/activation/:subjectId"}>
                                <ContentWithNav>
                                    <Activation />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute
                                path={"/tests"}
                                exact
                            >
                                <ContentWithNav>
                                    <Tests />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/tests/:testId"}>
                                <ContentWithNav>
                                    <TestDetails />
                                </ContentWithNav>
                            </PrivateRoute>
                            <PrivateRoute path={"/prepare-test/:subjectId"}>
                                <ContentWithNav>
                                    <Activation isPrepareForTest />
                                </ContentWithNav>
                            </PrivateRoute>
                            {/* redirect for invalid paths */}
                            <PrivateRoute>
                                <Redirect to="/home" />
                            </PrivateRoute>
                        </Switch>
                    </Router>
                </LanguagesContextWrapper>
            </Background>
        </ThemeProvider>
    );
};

export default Layout;
