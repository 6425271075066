// REACT
import React, { FunctionComponent } from "react";

// UTILS
import "../../../assets/phase6-icon-font/p6-icon-font.css";
import styled from "styled-components";

// todo: can we check those icon names from the css file?
export type TIconNames =
    | "achievement-task"
    | "activation"
    | "add"
    | "add-to-library"
    | "adults"
    | "aea"
    | "alarm-clock"
    | "alert"
    | "annotation"
    | "annotation-alt"
    | "appearance"
    | "apple"
    | "arrow-back-mobile"
    | "arrow-both"
    | "arrow-left"
    | "arrow-right"
    | "arrows-right-left"
    | "audio"
    | "avatar"
    | "bulb"
    | "calendar-check"
    | "calendar-cross"
    | "calendar-day"
    | "calendar-edit"
    | "camera"
    | "cards"
    | "cards-2"
    | "cards_1"
    | "cart"
    | "challenge"
    | "challenge-award"
    | "change-avatar"
    | "change-folder"
    | "check-answer"
    | "checkbox"
    | "checked"
    | "chevron-down"
    | "chevron-left"
    | "chevron-right"
    | "chevron-up"
    | "child-lock"
    | "child-unlock"
    | "clipboard"
    | "clock"
    | "close"
    | "cloud"
    | "compass"
    | "context-menu"
    | "correct"
    | "counter"
    | "cube"
    | "cup"
    | "cup-learned"
    | "darkmode"
    | "data-privacy-best"
    | "data-processing"
    | "data-protection-awaiting"
    | "data-protection-none"
    | "data-protection-on"
    | "database"
    | "dictionary"
    | "discount"
    | "discount-group"
    | "documents"
    | "dot-menu"
    | "download"
    | "drag"
    | "duplicate"
    | "edit"
    | "edit-avatar"
    | "exercise-failed"
    | "exercise-ok"
    | "eye"
    | "eye-closed"
    | "family"
    | "feedback"
    | "feedback-2"
    | "file"
    | "filter"
    | "finish"
    | "first-steps"
    | "flag"
    | "flowchart"
    | "folder"
    | "games"
    | "genius"
    | "globe"
    | "google"
    | "group"
    | "hand"
    | "heart"
    | "help"
    | "history"
    | "house"
    | "imprint"
    | "in-10-false"
    | "in-use"
    | "info"
    | "keys"
    | "keys-off"
    | "leaderboard"
    | "leaderboard-nav"
    | "learn"
    | "library"
    | "limit"
    | "lock"
    | "login"
    | "logout"
    | "logout-Kopie-32"
    | "logout-Kopie-35"
    | "logout-Kopie-36"
    | "magic-wand"
    | "mail"
    | "menu"
    | "mic"
    | "my-school"
    | "my-school-set"
    | "no-internet"
    | "no-star"
    | "notification"
    | "one-star"
    | "overview"
    | "p6"
    | "paint"
    | "parents"
    | "participants"
    | "paused"
    | "phase-up-down"
    | "pic"
    | "pin"
    | "pin-off"
    | "placement"
    | "play"
    | "plus"
    | "preview"
    | "qr-scan"
    | "random"
    | "refresh"
    | "reports"
    | "reports-add-user"
    | "reports-family"
    | "reports-group"
    | "reprofile"
    | "reset"
    | "reset-alt"
    | "rocket"
    | "running-time"
    | "scan"
    | "school"
    | "school-building"
    | "school_building"
    | "school_building_2"
    | "search"
    | "search-book"
    | "select-all"
    | "send"
    | "sent"
    | "settings"
    | "settings-alt"
    | "share"
    | "share-ios"
    | "skip"
    | "sleep"
    | "sliders"
    | "smartphone"
    | "speaker"
    | "special-character"
    | "speech"
    | "speech-error"
    | "star-full"
    | "star-minus"
    | "star-plus"
    | "stop"
    | "stop-watch"
    | "students"
    | "stuff"
    | "support"
    | "switch"
    | "switch-set"
    | "sync"
    | "task"
    | "teacher"
    | "test"
    | "three-stars"
    | "thumb-up"
    | "translate"
    | "translate-set"
    | "trash"
    | "two-stars"
    | "unconfirmed"
    | "undone"
    | "unlock"
    | "uppercase"
    | "user"
    | "user-avatar"
    | "user-unconfirmed"
    | "vocabulary-list"
    | "vocabulary-list-unset"
    | "wishlist"
    | "workbook"
    | "wrong";

type Props = {
    name: TIconNames;
    className?: string;
    size?: TIconSize;
    highlighted?: boolean;
    onClick?: any;
};

type TIconSize = "small" | "standard" | "medium" | "big";

const iconSizes: Record<TIconSize, string> = {
    small: "24px",
    standard: "32px",
    medium: "48px",
    big: "80px",
};

interface StyledIconProps {
    size: TIconSize;
    highlighted: boolean;
}

const StyledIcon = styled.i<StyledIconProps>`
    font-size: ${(props) => iconSizes[props.size]};
    color: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.text)};
`;

const PhaseSixIcon: FunctionComponent<Props> = (props: Props) => {
    const { name, className, size = "standard", highlighted = false, ...restProps } = props;
    return (
        <StyledIcon
            className={`p6-icon-${name} ${className}`}
            size={size}
            highlighted={highlighted}
            {...restProps}
        />
    );
};

export default PhaseSixIcon;
