// LIBRARIES
import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// REDUX
import { selectors as subjectsSelector, actions as subjectsActions } from "../../../redux/subjects/subjectsSlice";
import { actions as practiceActions } from "../../../redux/learning/learningSlice";
import { selectors as usersSelector } from "../../../redux/user/userSlice";
import { actions as testsActions, selectors as testsSelectors } from "../../../redux/tests/testsSlice";

//TYPES
import { SubjectData } from "p6m-subjects";

// COMPONENTS
import ActiveSubject from "./ActiveSubject";

interface ActiveSubjectProps {
    openShop: () => void;
    userRoles: { isParent: boolean; isTeacher: boolean };
    userFirstName: string | undefined;
    allSubjects: SubjectData[];
}

const ActiveSubjectWrapper: React.FC<ActiveSubjectProps> = (props) => {
    const activeSubject = useSelector(subjectsSelector.activeSubject);
    const familySubjects = useSelector(subjectsSelector.familySubjectsDerived);
    const dueToday = useSelector(subjectsSelector.cardCountShouldPracticeToday);
    const possibleToday = useSelector(subjectsSelector.additionalCardsCanBePracticedToday);
    const dueTomorrow = useSelector(subjectsSelector.cardCountShouldPracticeTomorrow);
    const learnNew = useSelector(subjectsSelector.cardCountLearnNew);
    const hasActivatedCards = useSelector(subjectsSelector.hasActivatedCards);
    const userHasPremium = useSelector(usersSelector.userHasPremium);
    const activationMetaData = useSelector(testsSelectors.activationMetaData);
    const firstPracticeFinished: boolean = useSelector(usersSelector.userMetadata).hasFirstPractice;

    const history = useHistory();
    const dispatch = useDispatch();

    const activeSubjectIsValid = !!props.allSubjects.find(
        (subject) => subject.subjectMetadata.subjectIdToOwner.id === activeSubject?.subjectMetadata.subjectIdToOwner.id
    );

    const onTrainingToday = useCallback(() => {
        if (!activeSubject) return;
        const {
            subjectMetadata: {
                subjectIdToOwner: { id: subjectId },
            },
        } = activeSubject;
        dispatch(practiceActions.startPracticeWithDueCards({ subjectId, history, limit: dueToday }));
    }, [activeSubject, dueToday, dispatch, history]);

    const onAdditionalToday = useCallback(() => {
        if (!activeSubject) return;
        const {
            subjectMetadata: {
                subjectIdToOwner: { id: subjectId },
            },
        } = activeSubject;
        dispatch(practiceActions.startPracticeWithAdditionalCards({ subjectId, history, limit: possibleToday }));
    }, [activeSubject, possibleToday, dispatch, history]);

    const onGoToReports = useCallback(() => {
        if (!activeSubject) return;
        const {
            subjectMetadata: {
                subjectIdToOwner: { id: subjectId },
            },
        } = activeSubject;
        history.push("/reports/?subjectId=" + subjectId);
    }, [activeSubject, history]);

    const onGoToTraining = useCallback(
        (type: "today" | "additional" | "tomorrow") => {
            const actions = {
                today: onTrainingToday,
                additional: onAdditionalToday,
                tomorrow: onGoToReports,
            };
            actions[type]();
        },
        [onTrainingToday, onAdditionalToday, onGoToReports]
    );

    const onAddCards = () => {
        if (!activeSubject) return;
        const { subjectMetadata: { subjectIdToOwner: { id = "" } = {} } = {} } = activeSubject;
        if (!id) return;
        // ensure the user will end up on the activation list
        if (!!activationMetaData) dispatch(testsActions.setActivationMetaData(null));
        history.push(`/activation/${id}`);
    };

    let activeDashboardSubject = null;

    if (activeSubject && activeSubjectIsValid) {
        activeDashboardSubject = { ...activeSubject };
        if (props.userRoles.isParent || props.userRoles.isTeacher) {
            const userName = familySubjects.find(
                (subject) =>
                    subject.subjectMetadata.subjectIdToOwner.id === activeSubject.subjectMetadata.subjectIdToOwner.id
            )?.userName;
            if (userName) {
                activeDashboardSubject = { ...activeSubject, userName: userName };
            } else {
                activeDashboardSubject = { ...activeSubject, userName: props.userFirstName };
            }
        }
    }

    const onGoToTestsAndExercises = () => {
        dispatch(testsActions.setSubject(activeSubject || undefined));
        history.push("/tests");
    };

    useEffect(() => {
        if (!activeSubjectIsValid) {
            dispatch(subjectsActions.setActiveSubjectId(null));
        }
    }, [activeSubjectIsValid]);

    return (
        <ActiveSubject
            activeSubject={activeDashboardSubject}
            dueToday={dueToday}
            possibleToday={possibleToday}
            dueTomorrow={dueTomorrow}
            learnNew={learnNew}
            hasActivatedCards={hasActivatedCards}
            userHasPremium={userHasPremium}
            firstPracticeFinished={firstPracticeFinished}
            onGoToTraining={onGoToTraining}
            onGoToTestsAndExercises={onGoToTestsAndExercises}
            onAddCards={onAddCards}
            openShop={props.openShop}
            userRoles={props.userRoles}
        />
    );
};

export default ActiveSubjectWrapper;
