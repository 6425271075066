//LIBRARIES
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { T, useT } from "@transifex/react";

//STYLES
import * as Styled from "../../basic/modal/styles";

//COMPONENTS
import CheckboxInput from "../../basic/checkboxInput/CheckboxInput";

//TYPES
import { GlobalModalProps } from "../../../helpers/Modal";

interface ConfirmEditModalProps extends GlobalModalProps {
    save: () => void;
    dontShowWarning: boolean;
    setDontShowWarning: (dontShow: boolean) => void;
}

const Background = styled.div`
    height: 100%;
    width: 100%;
    padding: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
`;
const ButtonsContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundMain};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;
const Button = styled.span<{ highlighted?: boolean }>`
    margin-left: ${(props) => props.theme.base.spacing};
    padding: ${(props) => props.theme.base.spacingSmall};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    background: ${(props) => (props.highlighted ? props.theme.colors.primary : props.theme.colors.backgroundMain)};
    color: ${(props) => (props.highlighted ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    border: ${(props) => (props.highlighted ? "" : `1px solid ${props.theme.colors.text}`)};
    text-transform: uppercase;
    text-align: center;
    font-size: ${({ theme: { base } }) => base.bigSize};
    cursor: pointer;
`;
const Description = styled.p`
    color: ${(props) => props.theme.colors.text};
`;

const DefaultHeader: FunctionComponent<{ title: string; close: () => any }> = ({ title, close }) => (
    <Styled.ModalHeader>
        <Styled.Title>{title.toUpperCase() || ""}</Styled.Title>
        <Styled.CloseIcon
            name={"close"}
            onClick={close}
        />
    </Styled.ModalHeader>
);

const ConfirmEditModal: React.FC<ConfirmEditModalProps> = (props) => {
    const t = useT();

    const assign_t = t("Save changes", { _tags: "subject,modal,title" });
    const dontShowAgain_t = t("Don't show this message again", { _tags: "subject,modal,title" });
    const cancel_t = t("Cancel", { _tags: "subject,modal,button" });
    const saveContent_t = t("Save", { _tags: "subject,modal,button" });

    return (
        <Background>
            <DefaultHeader
                title={assign_t}
                close={props.close}
            />
            <Content>
                <Description>
                    <T _str="Please note that previous entries for this card will be deleted from the report 'Difficult cards'." />
                </Description>
                <CheckboxInput
                    label={dontShowAgain_t}
                    checked={props.dontShowWarning}
                    onChange={(checked) => props.setDontShowWarning(checked)}
                />
            </Content>
            <ButtonsContainer>
                <Button onClick={props.close}>{cancel_t}</Button>
                <Button
                    onClick={props.save}
                    highlighted
                >
                    {saveContent_t}
                </Button>
            </ButtonsContainer>
        </Background>
    );
};

export default ConfirmEditModal;
