import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { IUserMetadata } from "p6m-user";
import { selectors as userSelectors } from "../../../../redux/user/userSlice";

import Component from "./LeaderBoardItem";

export const LeaderBoardItem: FunctionComponent = () => {
    const userData: IUserMetadata = useSelector(userSelectors.userMetadata);
    const { hasFirstPractice = false } = userData;

    return <Component disabled={!hasFirstPractice} />;
};
