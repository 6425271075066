import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, selectors } from "../../../../redux/add/addSlice";
import { actions as responseActions } from "../../../../redux/response/responseSlice";
import Component, { Props as ComponentProps } from "../../../complex/textEditor/CardEditor";
import { actions as modalActions } from "../../../../redux/modal/modalSlice";
import { GlobalModalView } from "../../../../helpers/Modal";

export type Props = {
    tabIndex: number;
    type: "question" | "answer";
    positions?: ComponentProps["positions"];
    getRef?: ComponentProps["getRef"];
    placeholder?: string;
    autoFocus?: boolean;
};

export const CardEditor: FunctionComponent<Props> = (props) => {
    const { tabIndex, type, positions, getRef, placeholder = "Gib hier den Text ein", autoFocus } = props;
    const targetSelectors = (
        {
            question: {
                text: selectors.getAddCardQuestionText,
                annotation: selectors.getAddCardQuestionAnnotation,
                images: selectors.getAddCardQuestionImages,
                audios: selectors.getAddCardQuestionAudios,
            },
            answer: {
                text: selectors.getAddCardAnswerText,
                annotation: selectors.getAddCardAnswerAnnotation,
                images: selectors.getAddCardAnswerImages,
                audios: selectors.getAddCardAnswerAudios,
            },
        } as Record<Props["type"], any>
    )[type];

    const questionLang = useSelector(selectors.getAddCardsPrimaryLang);
    const answerLang = useSelector(selectors.getAddCardsSecondaryLang);

    const text: string = useSelector(targetSelectors.text) || "";
    const images: string[] = useSelector(targetSelectors.images) || [];
    const audios: string[] = useSelector(targetSelectors.audios) || [];
    const annotation: string = useSelector(targetSelectors.annotation) || "";

    const dispatch = useDispatch();

    const onChange = useCallback(
        (text: string) => {
            const action = (
                {
                    question: actions.setQuestionText,
                    answer: actions.setAnswerText,
                } as Record<Props["type"], any>
            )[type];
            dispatch(action(text));
            dispatch(responseActions.cancelResponse());
        },
        [dispatch, type]
    );

    const onAnnotationChange = useCallback(
        (text: string) => {
            const action = (
                {
                    question: actions.setQuestionAnnotation,
                    answer: actions.setAnswerAnnotation,
                } as Record<Props["type"], any>
            )[type];
            dispatch(action(text));
        },
        [dispatch, type]
    );

    const onFilesChange = useCallback(
        (files: string[]) => {
            const action = (
                {
                    question: {
                        image: actions.setQuestionImages,
                        audio: actions.setQuestionAudios,
                    },
                    answer: {
                        image: actions.setAnswerImages,
                        audio: actions.setAnswerAudios,
                    },
                } as Record<Props["type"], Record<"image" | "audio", any>>
            )[type];

            const fileGetter = function (key: "image" | "audio") {
                return files
                    .filter((file: string) => file.split(":")[1] === key)
                    .map((file: string) => file.split(":")[0]);
            };

            const changeImages: string[] = fileGetter("image");
            const changeAudios: string[] = fileGetter("audio");

            if (images.length > changeImages.length || audios.length > changeAudios.length) {
                // delete
                const data = {
                    files: { changeImages, changeAudios },
                    imageAction: action.image,
                    audioAction: action.audio,
                };
                dispatch(modalActions.setData(data));
                dispatch(modalActions.setModalView(GlobalModalView.DeleteMedia));
                return;
            }
            // add
            dispatch(action.image(changeImages));
            dispatch(action.audio(changeAudios));
        },
        [audios, dispatch, images, type]
    );

    const files = useMemo(() => {
        return Object.entries({
            image: images,
            audio: audios,
        }).reduce((result: string[], [key, files]) => {
            return result.concat(files.map((file) => `${file}:${key}`));
        }, []);
    }, [images, audios]);

    return (
        <Component
            tabIndex={tabIndex}
            configuration={"ADD"}
            languageCode={type === "question" ? questionLang : answerLang}
            placeholder={placeholder}
            value={text}
            onChange={onChange}
            files={files}
            positions={positions}
            onFilesChange={onFilesChange}
            annotation={annotation}
            onAnnotationChange={onAnnotationChange}
            getRef={getRef}
            autoFocus={autoFocus}
        />
    );
};
