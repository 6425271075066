import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const FixedWrapper = styled.div<{ scroll: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: ${({ theme: { base } }) => base.modalZIndex};
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.3s, height 0s 0.3s;

    &.active {
        height: 100%;
        opacity: 1;
        transition: opacity 0.3s;
        ${({ scroll }) => (scroll ? "overflow: auto;" : "")}
    }
`;

export const ModalWrapper = styled.div`
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    width: 100%;
    padding: 20px 0;
`;

export const Background = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalBody = styled.div<{ width?: number; css?: any }>`
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: ${({ width = 768 }) => width}px;
    max-width: 90%;
    margin: auto;
    border-radius: ${({ theme: { base } }) => base.borderRadiusBig};
    background-color: ${({ theme: { colors } }) => colors.backgroundContrast};
    color: ${({ theme: { colors } }) => colors.text};
    overflow: hidden;
    ${({ css }) => css}
`;

export const ModalContent = styled.div<{ scroll: boolean }>`
    ${({ scroll }) => (scroll ? "overflow: auto;" : "")}
`;

export const CloseIcon = styled(PhaseSixIcon)`
    color: inherit;
    float: right;
    position: relative;
    cursor: pointer;
`;

export const Title = styled.span`
    font-size: ${({ theme: { base } }) => base.bigSize};
`;

export const ModalHeader = styled.div<{ error?: boolean }>`
    padding: ${({ theme: { base } }) => base.spacingSmall};
    background-color: ${({ theme: { colors }, error }) => (error ? colors.red : colors.primary)};
    color: ${(props) => props.theme.colors.backgroundContrast};
    font-size: ${({ theme: { base } }) => base.size};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
