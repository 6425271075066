import React, { FunctionComponent, memo, forwardRef } from "react";
import * as Styled from "../../basic/modal/styles";
import Loading from "../../connected/loading/Loading";

import {
    Background,
    StyledIframe,
    LoadingBox,
} from "./styles";

export interface ShareModalProps {
    url: string;
    isIframeLoading: boolean;
    close: () => any;
}

const DefaultHeader: FunctionComponent<{ close: () => any }> = ({ close }) => (
    <Styled.ModalHeader>
        &nbsp;
        <Styled.CloseIcon
            onClick={close}
            name={"close"}
        />
    </Styled.ModalHeader>
);

const DataProtectionModal = forwardRef<HTMLIFrameElement, ShareModalProps>((props, ref) => {
    const { url, isIframeLoading, close } = props;

    return (
        <Background>
            <DefaultHeader
                close={close}
            />
            {isIframeLoading && <LoadingBox>
                <Loading />    
            </LoadingBox>}
            <StyledIframe src={url} ref={ref} />
        </Background>
    );
});

export default memo(DataProtectionModal);
