import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useT } from "@transifex/react";

//REDUX
import { selectors, actions } from "../../../../redux/add/addSlice";
import { selectors as subjSelectors, actions as subjActions } from "../../../../redux/subjects/subjectsSlice";
import { selectors as userSelectors } from "../../../../redux/user/userSlice";

//HELPERS
import { generateUuid } from "../../../../helpers/Id";
import { checkMinValue, checkMaxValue, checkUniqValue } from "../../../../helpers/Validators";
import { generateSubjectTemplate } from "../../../../helpers/Subjects";
import { getNextUnitOrder } from "../../../../helpers/Units";

//COMPONENTS
import Component, { Props as ComponentProps } from "../../../complex/addCards/contentModal/ContentModal";

export type ValidationErrors = "MIN_ERROR" | "MAX_ERROR" | "UNIQ_ERROR";

export const ContentModal: FunctionComponent = () => {
    const modal = useSelector(selectors.getAddCardModal);
    const selectedSubjectId = useSelector(selectors.getAddCardsSubjectId);
    const selectedUnitId = useSelector(selectors.getAddCardsUnitId);
    const shareContent = useSelector(selectors.getSharingContent);
    const [creationSidebar, setSidebar] = useState<ComponentProps["showCreationSidebar"]>(false);
    const [editItem, setEditItem] = useState<string | undefined>();
    const userDnsId = useSelector(userSelectors.userId);
    const subjects = useSelector(subjSelectors.librarySubjects);
    const units = useSelector(subjSelectors.getUnitsBySubjectId(selectedSubjectId, "librarySubjects"));
    const dispatch = useDispatch();
    const t = useT();

    const t_errorsEmpty = t("You have to enter a title for the content.", {});
    const t_errorsUniq = t("There is already an item with that title", {});
    const t_errorsMax = t("Value is too long", {});

    useEffect(() => {
        setEditItem(undefined);
        setSidebar(false);
    }, [modal]);

    const goBackCallback: ComponentProps["goBack"] = useCallback(() => {
        dispatch(actions.setCardSubjectId(selectedSubjectId));
        dispatch(actions.setCardUnitId(selectedUnitId));
        dispatch(actions.setModal("subject"));
    }, [dispatch]);

    const onSubjectSelectCallback: ComponentProps["onSubjectSelect"] = useCallback(
        (subject) => {
            const {
                subjectMetadata: {
                    subjectIdToOwner: { id },
                },
            } = subject;
            dispatch(actions.setCardSubjectId(id));
            dispatch(actions.setModal("unit"));
        },
        [dispatch]
    );

    const onUnitSelectCallback: ComponentProps["onUnitSelect"] = useCallback(
        (unit) => {
            const {
                unitId: { id },
            } = unit;
            dispatch(actions.setCardUnitId(id));
            dispatch(actions.setModal(undefined));
        },
        [dispatch]
    );

    const afterCloseCallback: ComponentProps["afterClose"] = useCallback(() => {
        dispatch(actions.setModal(undefined));
    }, [dispatch]);

    const onAddClickCallback: ComponentProps["onAddClick"] = useCallback(() => {
        setSidebar(true);
    }, []);

    const onCreationCloseCallback: ComponentProps["onCreationClose"] = useCallback(() => {
        setSidebar(false);
    }, []);

    const getErrorText = useCallback(
        (key: ValidationErrors) => {
            const relation: Record<ValidationErrors, string> = {
                MIN_ERROR: t_errorsEmpty,
                MAX_ERROR: t_errorsMax,
                UNIQ_ERROR: t_errorsUniq,
            };

            return relation[key];
        },
        [t_errorsEmpty, t_errorsMax, t_errorsUniq]
    );

    const validateSubject = useCallback(
        (value: string, subjId?: string): ValidationErrors | undefined => {
            value = value.trim();
            const subjNames: string[] = subjects.map(({ subjectContent: { name } }) => name);

            if (subjId) {
                const index = subjects.findIndex(
                    ({
                        subjectMetadata: {
                            subjectIdToOwner: { id },
                        },
                    }) => id === subjId
                );
                if (index >= 0) {
                    subjNames.splice(index, 1);
                }
            }

            const relation: Record<ValidationErrors, Function> = {
                MIN_ERROR: () => checkMinValue(value),
                MAX_ERROR: () => checkMaxValue(value, 254),
                UNIQ_ERROR: () => checkUniqValue(value, subjNames),
            };

            return Object.keys(relation).find((key) => relation[key as ValidationErrors]()) as ValidationErrors;
        },
        [subjects]
    );

    const onSubjectCreateCallback: ComponentProps["onSubjectCreate"] = useCallback(
        (result) => {
            if (!userDnsId) return Promise.reject();
            const { value, primaryLang, secondaryLang } = result;

            const error = validateSubject(value);

            if (error) {
                return Promise.reject(getErrorText(error));
            }

            dispatch(
                subjActions.complexSubjectsRest({
                    created: [
                        generateSubjectTemplate({
                            creatorId: userDnsId,
                            name: value,
                            primaryLang,
                            secondaryLang,
                            withDefaultUnit: true,
                        }),
                    ],
                })
            );
            return Promise.resolve();
        },
        [userDnsId, validateSubject, getErrorText, dispatch]
    );

    const validateUnit = useCallback(
        (value: string, unitId?: string): ValidationErrors | undefined => {
            value = value.trim();
            if (!units) return;
            const unitsNames: string[] = units.map(({ unitContent: { name } }) => name);
            if (unitId) {
                const index = units.findIndex(({ unitId: { id } }) => id === unitId);
                if (index >= 0) {
                    unitsNames.splice(index, 1);
                }
            }
            const relation: Record<ValidationErrors, Function> = {
                MIN_ERROR: () => checkMinValue(value),
                MAX_ERROR: () => checkMaxValue(value, 254),
                UNIQ_ERROR: () => checkUniqValue(value, unitsNames),
            };

            return Object.keys(relation).find((key) => relation[key as ValidationErrors]()) as ValidationErrors;
        },
        [units]
    );

    const onUnitCreateCallback: ComponentProps["onUnitCreate"] = useCallback(
        (name) => {
            if (!userDnsId) return Promise.reject();

            const error = validateUnit(name);
            const order = getNextUnitOrder(units);

            if (error) {
                return Promise.reject(getErrorText(error));
            }

            dispatch(
                subjActions.complexUnitsRest({
                    created: [
                        {
                            unitId: generateUuid(),
                            ownerId: userDnsId,
                            subjectId: selectedSubjectId,
                            order,
                            name,
                        },
                    ],
                })
            );
            return Promise.resolve();
        },
        [userDnsId, selectedSubjectId, validateUnit, getErrorText, dispatch]
    );

    const onSubjectEditCallback: ComponentProps["onSubjectEdit"] = useCallback(
        (subject, result) => {
            if (!userDnsId) return Promise.reject();
            const {
                subjectMetadata: {
                    subjectIdToOwner: { id, ownerId },
                },
                subjectContent: { primaryLang, secondaryLang, name },
            } = subject;

            if (userDnsId !== ownerId) return Promise.reject();

            const {
                value = name,
                primaryLang: newPrimaryLang = primaryLang,
                secondaryLang: newSecondaryLang = secondaryLang,
            } = result;

            const error = validateSubject(value, id);

            if (error) {
                return Promise.reject(getErrorText(error));
            }

            dispatch(
                subjActions.complexSubjectsRest({
                    updated: [
                        generateSubjectTemplate({
                            subjectId: id,
                            creatorId: userDnsId,
                            name: value,
                            primaryLang: newPrimaryLang,
                            secondaryLang: newSecondaryLang,
                        }),
                    ],
                })
            );
            return Promise.resolve();
        },
        [dispatch, userDnsId, validateSubject, getErrorText]
    );

    const onUnitEditCallback: ComponentProps["onUnitEdit"] = useCallback(
        (unit, name) => {
            if (!userDnsId) return Promise.reject();
            const {
                unitId: { id: unitId },
                unitContent: {
                    subjectIdToOwner: { ownerId, id },
                },
            } = unit;

            if (userDnsId !== ownerId) return Promise.reject();

            const error = validateUnit(name, unitId);

            if (error) {
                return Promise.reject(getErrorText(error));
            }

            dispatch(
                subjActions.complexUnitsRest({
                    updated: [
                        {
                            unitId,
                            ownerId,
                            subjectId: id,
                            name,
                        },
                    ],
                })
            );
            return Promise.resolve();
        },
        [dispatch, userDnsId, validateUnit, getErrorText]
    );

    const onUnitDeleteCallback: ComponentProps["onUnitDelete"] = useCallback(
        (unit) => {
            if (!userDnsId) return;
            const {
                unitId: { id: unitId },
                unitContent: {
                    subjectIdToOwner: { ownerId, id },
                    name,
                },
            } = unit;
            if (userDnsId !== ownerId) return;
            dispatch(
                subjActions.complexUnitsRest({
                    deleted: [
                        {
                            unitId,
                            ownerId,
                            subjectId: id,
                            name,
                        },
                    ],
                })
            );
        },
        [dispatch, userDnsId]
    );

    const onShare = useCallback(() => {
        dispatch(actions.setModal("share"));
    }, [dispatch]);

    const setEditItemCallback = useCallback((itemKey?: string) => {
        if (!itemKey) {
            setEditItem(undefined);
            return;
        }
        setEditItem(itemKey);
    }, []);

    const onDefaultUnitSelectCallback: ComponentProps["onDefaultUnitSelect"] = useCallback(() => {
        dispatch(actions.setCardUnitId(selectedUnitId));
        dispatch(actions.setModal(undefined));
    }, [dispatch]);

    const onDefaultSubjectSelectCallback: ComponentProps["onDefaultSubjectSelect"] = useCallback(function () {
        dispatch(actions.setCardSubjectId(selectedSubjectId));
        dispatch(actions.setModal("unit"));
    }, []);

    return (
        <Component
            subjects={subjects}
            units={units || []}
            type={modal || "subject"}
            active={!!modal}
            showCreationSidebar={creationSidebar}
            userDnsId={userDnsId}
            editItem={editItem}
            goBack={goBackCallback}
            onSubjectSelect={onSubjectSelectCallback}
            onUnitSelect={onUnitSelectCallback}
            afterClose={afterCloseCallback}
            onAddClick={onAddClickCallback}
            onCreationClose={onCreationCloseCallback}
            onSubjectCreate={onSubjectCreateCallback}
            onUnitCreate={onUnitCreateCallback}
            onSubjectEdit={onSubjectEditCallback}
            onUnitEdit={onUnitEditCallback}
            onUnitDelete={onUnitDeleteCallback}
            onShare={!!shareContent ? onShare : undefined}
            setEditItem={setEditItemCallback}
            onDefaultUnitSelect={onDefaultUnitSelectCallback}
            onDefaultSubjectSelect={onDefaultSubjectSelectCallback}
        />
    );
};
